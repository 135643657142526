import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import api from '~/services/api';
import { editCuponsRequest } from '~/store/modules/cupons/actions';

export default function Edit() {
  const {
    params: { id },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.profile);
  const [getData, setData] = useState({});
  const [loading, setLoading] = useState(true);

  function handleChange(e) {
    setData({
      ...getData,
      [e.target.name]:
        e.target.name === 'is_limited' ? e.target.checked : e.target.value,
    });
  }

  useEffect(() => {
    async function loadEditCupom() {
      try {
        const response = await api.get(`/coupons/${user.id}/${id}`);
        setData({
          ...response.data,
          date_finish: moment
            .unix(response.data.valid_until)
            .format('YYYY-MM-DD'),
          hour_finish: moment.unix(response.data.valid_until).format('HH:mm'),
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    loadEditCupom();
  }, []);

  function handleCreateCupom(e) {
    e.preventDefault();

    dispatch(
      editCuponsRequest({
        ...getData,
        id,
        valid_until: moment(
          `${getData.date_finish} ${getData.hour_finish}`
        ).format('X'),
      })
    );
  }

  return (
    <>
      {loading ? (
        <div className="text-center">
          <div className="spinner spinner-primary mr-3"></div>
        </div>
      ) : (
        <div className="row">
          {console.log('getData', getData)}
          <div className="col-md-6">
            <form onSubmit={handleCreateCupom} className="pb-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Código de desconto</h5>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      defaultValue={getData.name || ''}
                      onChange={handleChange}
                      className="form-control text-uppercase"
                      placeholder="por exemplo, PROMODEPRIMAVERA"
                    />
                    <small>
                      Os clientes devem digitar esse código de desconto no
                      checkout, ao finalizar a compra.
                    </small>
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Valor</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail3" className="ul-form__label">
                        Valor do desconto:
                      </label>
                      <div className="input-right-icon">
                        <input
                          name="value"
                          defaultValue={getData.value || ''}
                          onChange={handleChange}
                          type="text"
                          className="form-control pr-4"
                          id="inputEmail3"
                          placeholder=""
                        />
                        <span className="span-right-input-icon">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Limites de uso</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        onChange={handleChange}
                        name="limit"
                        className="form-control"
                        defaultValue={getData.limit || ''}
                      />
                    </div>
                    <div className="form-group col-xs-12">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="is_limited"
                          checked={getData.is_limited ? 'checked' : false}
                        />
                        <span>
                          Limitar o número de total de vezes que este desconto
                          pode ser usado
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Datas ativas</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="">Data de Término</label>
                      <div className="input-right-icon">
                        <input
                          name="date_finish"
                          type="date"
                          defaultValue={moment
                            .unix(getData.valid_until)
                            .format('YYYY-MM-DD')}
                          required
                          className="form-control"
                          id="inputEmail3"
                          onChange={handleChange}
                          placeholder="Data de Término"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="">Hora de Término</label>
                      <div className="input-right-icon">
                        <input
                          type="time"
                          min="00:00"
                          defaultValue={moment
                            .unix(getData.valid_until)
                            .format('HH:mm')}
                          required
                          max="23:59"
                          onChange={handleChange}
                          name="hour_finish"
                          format={'HH:mm'}
                          className="timePicker form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-auto ml-auto">
                  <Link to={'/cupons'} className="btn btn-light">
                    Cancelar
                  </Link>
                  <button type="submit" className="btn btn-primary ml-2">
                    {loading ? (
                      <div className="mr-2 spinner-border spinner-border-sm"></div>
                    ) : (
                      'Atualizar Cupom'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

import styled, { createGlobalStyle } from 'styled-components';

createGlobalStyle`
  body {
    background: linear-gradient(-45deg, #fff300, #fff300);
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  position: relative;
  width: 250px;
  height: 250px;

  .ghost {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #ededed;
    border: 5px solid #000;
    border-bottom: none;
    animation: float 2s ease-out infinite;
  }

  .ghost-copy {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #ededed;
    border: 1px solid #bfc0c0;
    border-bottom: none;
    animation: float 2s ease-out infinite;
    z-index: 0;
  }

  .face {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 20%;
  }

  .eye,
  .eye-right {
    position: absolute;
    background: #000000;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 40%;
  }

  .eye {
    left: 25%;
  }

  .eye-right {
    right: 25%;
  }

  .mouth {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent #000000 #000000 transparent;
    transform: rotate(45deg);
  }

  .one,
  .two,
  .three,
  .four {
    position: absolute;
    background: #ededed;
    top: 85%;
    width: 25%;
    height: 23%;
    border: 5px solid #000000;
    z-index: 0;
  }

  .one {
    border-radius: 0 0 100% 30%;
    left: -1px;
  }

  .two {
    left: 23%;
    border-radius: 0 0 50% 50%;
  }

  .three {
    left: 50%;
    border-radius: 0 0 50% 50%;
  }

  .four {
    left: 74.5%;
    border-radius: 0 0 30% 100%;
  }

  .shadow {
    position: absolute;
    width: 30%;
    height: 7%;
    background: #cccccc;
    left: 35%;
    top: 80%;
    border-radius: 50%;
    animation: scale 2s infinite;
  }

  .search {
    text-align: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  /*search style*/

  .search-bar {
    border: 1px solid #bfc0c0;
    padding: 5px;
    height: 20px;
    margin-left: -30px;
    width: 200px;
    outline: none;
  }

  .search-bar:focus {
    border: 1px solid #d3deea;
  }

  .search-btn {
    position: absolute;
    width: 30px;
    height: 32px;
    border: 1px solid #bfc0c0;
    background: #bfc0c0;
    text-align: center;
    color: #ededed;
    cursor: pointer;
    font-size: 1em;
    outline: none;
  }

  .search-btn:hover {
    background: #ededed;
    border: 1px solid #ededed;
    color: #bfc0c0;
    transition: all 0.2s ease;
  }

  footer {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 0.8em;
    text-transform: uppercase;
    padding: 10px;
    color: #ea7996;
    letter-spacing: 3px;
    font-family: 'Lato', sans-serif;
  }

  footer a {
    color: #ffffff;
    text-decoration: none;
  }

  footer a:hover {
    color: #7d7d7d;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes float {
    50% {
      transform: translateY(15px);
    }
  }
`;
export const Background = styled.div``;
export const Header = styled.div`
  margin-top: 30px;
  h1 {
    font-family: 'Abril Fatface', serif;
    color: #000000;
    text-align: center;
    font-size: 9em;
    margin: 0;
    text-shadow: -1px 0 #bfc0c0, 0 1px #bfc0c0, 1px 0 #bfc0c0, 0 -1px #bfc0c0;
  }

  h3 {
    font-family: 'Lato', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
    margin-top: -20px;
    font-weight: 900;
  }
`;
export const Footer = styled.div`
  margin-top: 10px;
  p {
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: #585959;
    font-size: 0.6em;
    margin-top: -20px;
    text-transform: uppercase;
  }
`;

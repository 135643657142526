import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  completeLoginWithPlus,
} from '~/store/modules/auth/actions';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LoginPlus = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search))
    if (searchParams?.code) {
      dispatch(completeLoginWithPlus(searchParams))
    } else {
      history.push("/")
    }
  }, [history, dispatch, location])

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh"
    }}><AiOutlineLoading3Quarters className="spin" size={30} /></div>
  )
}

export default LoginPlus

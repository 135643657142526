export function newCupomRequest(data) {
  return {
    type: "@cupons/NEW_CUPOM_REQUEST",
    payload: data,
  };
}

export function newCupomSuccess(data) {
  return {
    type: "@cupons/NEW_CUPOM_SUCCESS",
    payload: { data },
  };
}

export function newCupomError() {
  return {
    type: "@cupons/NEW_CUPOM_ERROR",
  };
}
export function loadCuponsRequest() {
  return {
    type: "@cupons/LOAD_CUPONS_REQUEST",
  };
}

export function loadCuponsSuccess(cupons) {
  return {
    type: "@cupons/LOAD_CUPONS_SUCCESS",
    payload: { cupons },
  };
}

export function loadCuponsError() {
  return {
    type: "@cupons/LOAD_CUPONS_ERROR",
  };
}

export function editCuponsRequest(data) {
  return {
    type: "@cupons/EDIT_CUPONS_REQUEST",
    payload: data,
  };
}

export function editCuponsSuccess() {
  return {
    type: "@cupons/EDIT_CUPONS_SUCCESS",
  };
}

export function editCuponsError() {
  return {
    type: "@cupons/EDIT_CUPONS_ERROR",
  };
}

export function deleteCuponsRequest(id) {
  return {
    type: "@cupons/DELETE_CUPONS_REQUEST",
    payload: { id },
  };
}

export function deleteCuponsSuccess() {
  return {
    type: "@cupons/DELETE_CUPONS_SUCCESS",
  };
}

export function deleteCuponsError() {
  return {
    type: "@cupons/DELETE_CUPONS_ERROR",
  };
}

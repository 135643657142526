export const navigations = [
  {
    name: 'Papos',
    description: 'Lorem ipsum dolor sit.',
    type: 'dropDown',
    icon: 'i-Speach-Bubble-3',
    url: 'papos',
  },
  {
    name: 'Minha Conta',
    description: 'Lorem ipsum dolor sit.',
    type: 'dropDown',
    icon: 'i-Eyeglasses-Smiley',
    url: 'minhaconta',
  },
  // {
  //   name: 'Cupons',
  //   description: 'Lorem ipsum dolor sit.',
  //   type: 'dropDown',
  //   icon: 'i-Tag-4',
  //   url: 'cupons',
  // },
  // {
  //   name: 'Integrações',
  //   description: 'Lorem ipsum dolor sit.',
  //   type: 'dropDown',
  //   icon: 'i-Tag-4',
  //   url: 'integracoes',
  // },
  {
    name: 'Ajuda',
    description: 'Lorem ipsum dolor sit.',
    type: 'dropDown',
    icon: 'i-Support',
    url: 'ajuda',
  },
  // {
  //   name: "Ajuda",
  //   description: "Lorem ipsum dolor sit.",
  //   type: "dropDown",
  //   icon: "i-Speach-Bubble-Asking",
  //   url: "ajuda",
  // },
];

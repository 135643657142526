import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Home from "./Home";
import Create from "./Create";
import Edit from "./Edit";

export default function CuponsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route path={`${path}/create`} component={Create} />
      <Route path={`${path}/edit/:id`} component={Edit} />
    </Switch>
  );
}

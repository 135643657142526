import styled from "styled-components";
import { Link } from "react-router-dom";

export const BoxForm = styled.div`
  display: block;
  margin: 20px;
  border-radius: 3px;

  .form {
    display: flex;
    flex-direction: column;
  }

  .form-check-input {
    margin-top: 0.2rem;
  }

  @media (max-width: 600px) {
    .form .div-days {
      flex-direction: column;
    }
  }

  h2 {
    margin-top: 10px;
    font-weight: bold;
  }

  .form .div-input:nth-child(n + 2) {
    margin-top: 10px;
  }

  .form .div-input label {
    margin: 0;
    font-weight: bold;
  }

  .form .div-input div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .form .div-input input {
    max-width: 150px;
    margin-right: 10px;
  }

  .btn-save {
    width: 170px;
    background: #5a00c9;
    font-weight: bold;
  }

  .form .div-days {
    display: flex;
    flex-wrap: wrap;
  }

  .form .div-days > div {
    margin-top: 12px;
    margin-right: 12px;
  }

  .form .div-time-pickers {
    margin-top: 12px;
    .ant-picker-input > input {
      font-size: 17px;
    }
  }

  .form .div-time-pickers h2 {
    margin: 3px;
    font-weight: bold;
  }

  .form .div-time-pickers span {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
  }
`;

export const BtnCancelar = styled(Link)`
  width: 170px;
  display: inline-block;
`;

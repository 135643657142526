import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager as toast } from 'react-notifications';
import moment from 'moment';

import { newCupomRequest } from '~/store/modules/cupons/actions';

export default function Create() {
  const loading = useSelector((state) => state.cupons.loading);
  const dispatch = useDispatch();
  const [getData, setData] = useState({});

  function handleChange(e) {
    setData({
      ...getData,
      [e.target.name]:
        e.target.name === 'is_limited'
          ? e.target.checked
          : e.target.value.toUpperCase(),
    });
  }

  function handleCreateCupom(e) {
    e.preventDefault();
    dispatch(
      newCupomRequest({
        ...getData,
        valid_until: moment(
          `${getData.date_finish} ${getData.hour_finish}`
        ).format('X'),
      })
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={handleCreateCupom} className="pb-4">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Código de desconto</h5>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    className="form-control text-uppercase"
                    placeholder="por exemplo, PROMODEPRIMAVERA"
                  />
                  <small>
                    Os clientes devem digitar esse código de desconto no
                    checkout, ao finalizar a compra.
                  </small>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Valor</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail3" className="ul-form__label">
                      Valor do desconto:
                    </label>
                    <div className="input-right-icon">
                      <input
                        name="value"
                        onChange={handleChange}
                        type="text"
                        className="form-control pr-4"
                        id="inputEmail3"
                        placeholder=""
                      />
                      <span className="span-right-input-icon">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Limites de uso</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="limit"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-xs-12">
                    <label className="checkbox checkbox-primary">
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="is_limited"
                      />
                      <span>
                        Limitar o número de total de vezes que este desconto
                        pode ser usado
                      </span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Datas ativas</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="">Data de Término</label>
                    <div className="input-right-icon">
                      <input
                        name="date_finish"
                        type="date"
                        required
                        className="form-control"
                        id="inputEmail3"
                        onChange={handleChange}
                        placeholder="Data de Término"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="">Hora de Término</label>
                    <div className="input-right-icon">
                      <input
                        type="time"
                        min="00:00"
                        required
                        max="23:59"
                        onChange={handleChange}
                        name="hour_finish"
                        //   value={moment(edit_papo.availability_end, "HH:mm")}
                        format={'HH:mm'}
                        className="timePicker form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-auto ml-auto">
                <Link to={'/cupons'} className="btn btn-light">
                  Cancelar
                </Link>
                <button type="submit" className="btn btn-primary ml-2">
                  {loading ? (
                    <div className="mr-2 spinner-border spinner-border-sm"></div>
                  ) : (
                    'Criar Cupom'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

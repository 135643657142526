import { all } from 'redux-saga/effects';
// user-org
import auth from './auth/sagas';
import users from './users/sagas';
import theme from './theme/sagas';
import cupons from './cupons/sagas'; 
import integrations from './integrations/sagaIntegration'; 

export default function* rootReducer() {
  return yield all([auth, users, theme, cupons, integrations]);
}

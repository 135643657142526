import produce from "immer";

const INITIAL_STATE = {
  menu: true,
};

export default function theme(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@theme/TOGGLE_MENU_REQUEST":
        draft.menu = !draft.menu;
        break;

      default:
        return state;
    }
  });
}

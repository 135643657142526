import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { NotificationManager as toast } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import api from '~/services/api';
import history from '~/services/history';
import { forgotPasswordRequest, forgotPasswordWithPlusRequest } from '~/store/modules/auth/actions';

import { updateProfileSuccess } from '~/store/modules/users/actions';

import { Form, FormGroup } from './styles';
import { getCookieFromString } from '~/helpers/cookies';

// const ForgotPasswordSchema = yup.object().shape({
//   email: yup.string().email('Invalid email').required('e-mail é obrigatório'),
// });

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit(e) {
    e.preventDefault();
    if (process.env.REACT_APP_HABILITAR_PLUS) {
      dispatch(forgotPasswordWithPlusRequest(e.target.email.value));
    } else {
      dispatch(forgotPasswordRequest(e.target.email.value));
    }
  }

  // useEffect(() => {
  //   googleSDK();
  // }, []);

  // async function prepareLoginButton() {
  //   window['gapi'].auth2.authorize(
  //     {
  //       response_type: 'code id_token permission',
  //       client_id: process.env.REACT_APP_CLIENT_ID_GOOGLE,
  //       scope: process.env.REACT_APP_SCOPE_GOOGLE,
  //     },
  //     async (responseAuthorize) => {
  //       try {
  //         if (responseAuthorize.error) {
  //           console.log(responseAuthorize.error);
  //           return toast.error('', 'Falha no login com o google');
  //         }

  //         let base64Url = responseAuthorize.id_token.split('.')[1]; // token you get
  //         let base64 = base64Url.replace('-', '+').replace(' ', '/');
  //         let id_payload = JSON.parse(
  //           Buffer.from(base64, 'base64').toString('binary')
  //         );
  //         const urlBackend = '/login/google';

  //         const responseBackend = await api.post(urlBackend, {
  //           code: responseAuthorize.code,
  //           data: {
  //             username: id_payload.email,
  //           },
  //         });

  //         localStorage.setItem('token', responseBackend.data.token);
  //         dispatch(updateProfileSuccess(responseBackend.data.user));
  //         history.push('/papos');
  //       } catch (err) {
  //         console.log('Error in auth google');
  //         return toast.error('', 'Falha no login com o google');
  //       }
  //     }
  //   );
  // }

  // function googleSDK() {
  //   window['googleSDKLoaded'] = () => {
  //     window['gapi'].load('auth2', () => {
  //       //this.auth2 = window['gapi'].auth2;
  //     });
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, 'script', 'google-jssdk');
  // }

  let utm_source = getCookieFromString('utm_source');

  let utm_campaign = getCookieFromString('utm_campaign');

  let utm_medium = getCookieFromString('utm_medium');

  return (
    <>
      <h1>Esqueceu a senha?</h1>
      <p>
        Já possui uma conta? <Link to={'/'}>Acessar</Link>
      </p>

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <input
            type="email"
            className="form-control"
            required
            name="email"
            aria-describedby="emailHelp"
            placeholder="email@example.com"
          />
          <i className="flaticon-mail-2"></i>
        </FormGroup>

        <button type="submit" className="btn-md mt-0 btn-submit">
          {loading ? (
            <AiOutlineLoading3Quarters className="spin" size={30} />
          ) : (
            ' Resetar'
          )}
        </button>

        <div className="extra-login clearfix">
          <span>Ou Acesse com</span>
        </div>

        <ul className="social-list">
          <li>
            <a href="#" className="facebook-bg" onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_BASE_URL + `/eplus/auth/login-social/facebook?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                window.location.href = linkToRedirect;
              }
            }}>
              <span className="facebook-i">
                <FaFacebook className="fab fa-facebook " size="1em" color="#fff" />
              </span>
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a href="#" className="google-bg" onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_BASE_URL + `/eplus/auth/login-social/google?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                window.location.href = linkToRedirect;
              }
            }}>
              <span className="google-i">
                <FaGoogle className="fab fa-google " size="1em" color="#fff" />
              </span>
              <span>Google</span>
            </a>
          </li>
        </ul>
      </Form>
    </>
  );
}

import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import Login from "~/pages/Login";
import SignUp from "~/pages/SignUp";
import ForgotPassword from "~/pages/Forgot";

import Papos from "~/pages/Papos";
import Cupons from "~/pages/Cupons";
import MinhaConta from "~/pages/MinhaConta";
import Integrations from "~/pages/integrations";
import Help from "~/pages/help";
import PageNuvemshop from "~/pages/nuvemshop";

import Auth from "~/components/auth";
import Authmp from "~/components/authmp";

import Error404 from "~/pages/Error404";

import { Route as RouteCustom } from 'react-router-dom';
import LoginPlus from "~/pages/LoginPlus";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <RouteCustom exact path="/eplus/auth/link-magico" component={LoginPlus} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />

      <Route path="/papos" component={Papos} isPrivate />
      <Route path="/cupons" component={Cupons} isPrivate />
      <Route path="/minhaconta" component={MinhaConta} isPrivate />
      <Route path="/integracoes" component={Integrations} isPrivate />
      <Route path="/ajuda" component={Help} isPrivate />
      <RouteCustom path="/nuvemshop" component={PageNuvemshop} />


      <Route path="/authmp" component={Authmp} isPrivate />
      <Route path="/auth" component={Auth} isPrivate />

      <Route component={Error404} />
    </Switch>
  );
}

import { Container, Content } from './styled'
import React from "react";
const Alert = (props) => {

    return (
        <Container background={props.background} margin={props.margin} padding={props.padding ? props.padding : "16px"}>
            <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="32" height="32" />
                    <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="currentColor" opacity="0.3" />
                    <rect fill="currentColor" x="11" y="9" width="2" height="7" rx="1" />
                    <rect fill="currentColor" x="11" y="17" width="2" height="2" rx="1" />
                    </g>
                </svg>
            </span>
            <Content>
                {props.children}
            </Content>
        </Container >
    );
}

export default Alert
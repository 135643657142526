import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, SimpleCard } from '@gull';
import { ZiggeoRecorder } from 'react-ziggeo';
import { SocialIcon } from 'react-social-icons';
import mercadopago from '~/assets/images/mercadopago.svg';
import googlecalendar from '~/assets/images/googlecalendar.png';
import api from '~/services/api';
import history from '~/services/history';
import {
  Wrapper,
  ButtonViewPassword,
  AskPapo,
  AvatarProfile,
  Input,
} from './styles';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { NotificationManager as toast } from 'react-notifications';

import {
  requestAuthGoogleDisconnect,
  requestAuthMP,
} from '~/store/modules/users/actions';

import { updateProfileSuccess } from '~/store/modules/users/actions';
import { Avatar } from 'antd';
import { Container, ContainerCards } from './styled';
import Card from './components/Card';

export default function MinhaConta() {
  const [video_token, setVideoToken] = useState(null);
  const [nameCopy, setNameCopy] = useState('');
  const [senha, setSenha] = useState('');
  const [mp_auth, setMpAuth] = useState(false);
  const [google_auth, setGoogleAuth] = useState(false);
  const [fileCopy, setFileCopy] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [facebook, setFacebook] = useState(
    useSelector((state) => state.users.profile.facebook)
  );
  const [instagram, setInstagram] = useState(
    useSelector((state) => state.users.profile.instagram)
  );
  const [twitter, setTwitter] = useState(
    useSelector((state) => state.users.profile.twitter)
  );
  const [youtube, setYoutube] = useState(
    useSelector((state) => state.users.profile.youtube)
  );
  const [twitch, setTwitch] = useState(
    useSelector((state) => state.users.profile.twitch)
  );
  const [tiktok, setTiktok] = useState(
    useSelector((state) => state.users.profile.tiktok)
  );
  const [website, setWebsite] = useState(
    useSelector((state) => state.users.profile.website)
  );
  const [whatsapp, setWhatsapp] = useState(
    useSelector((state) => state.users.profile.whatsapp)
  );
  const [category, setCategory] = useState(
    useSelector((state) => state.users.profile.category)
  );
  const [categories, setCategories] = useState([]);
  const [custom_link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState('');
  const [enable_video, setEnableVideo] = useState(true);
  const token = localStorage.getItem('token');
  const google_account = useSelector(
    (state) => state.users.profile.google_account
  );
  const photo_url = useSelector((state) => state.users.profile.photo_url);
  const custom_slug = useSelector((state) => state.users.profile.custom_slug);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(false);
  const dispatch = useDispatch();

  // console.log(
  //   'pablo aq',
  //   useSelector((state) => state.users.profile)
  // );

  const coverRef = useRef();

  function handleShowPassword() {
    setIsShowPassword(!isShowPassword);
  }

  useEffect(() => {
    async function fetchAPI() {
      const response = await api.get('papos', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("teste response",response)
      
      setNameCopy(response.data.user.name);
      setFileCopy(response.data.user.photo_url);
      setVideoToken(response.data.user.video_token);
      setName(response.data.user.name);
      setEmail(response.data.user.username);
      setLink(response.data.user.custom_slug);
      setTitle(response.data.user.title);
      setDescription(response.data.user.description);
      setFile(response.data.user.photo_url);
      setGoogleAuth(response.data.user.is_google_auth);
      setMpAuth(response.data.user.is_mp_auth);
      setEnableVideo(response.data.user.enable_video);
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    const fetchCategoriesData = () => {
      api
        .get('/categories')
        .then((result) => {
          setCategories(result.data);
        })
        .catch((err) => {
          console.log('Error on GET Categories', err);
        });
    };
    fetchCategoriesData();
  }, []);

  async function handleActiveGoogle() {
    try {
      const response = await api.get('auth', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      window.location.assign(response.data.redirect_url);
    } catch (err) {
      toast.error('', `${err.message}`);
    }
  }

  async function handleActiveMP() {
    try {
      const response = await api.get('authmp', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      window.location.assign(response.data.redirect_url);
    } catch (err) {
      toast.error('', `${err.message}`);
    }
  }

  async function desconectGoogle() {
    try {
      await api.get('/auth/revoke', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(requestAuthGoogleDisconnect());
      history.push('/papos');
    } catch (err) {
      toast.error('', `${err.message}`);
    }
  }

  async function desconectMP() {
    try {
      const formData = new FormData();
      formData.append('is_mp_auth', JSON.stringify(false));

      dispatch(requestAuthMP());
      await api.put('/users', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      history.push('/papos');

      window
        .open(
          'https://www.mercadopago.com.br/account/security/applications/connections',
          '_blank'
        )
        .focus();
    } catch (err) {
      toast.error('', `${err.message}`);
    }
  }

  async function handleToggleVideo(e) {
    e.persist();

    try {
      const formData = new FormData();
      formData.append('enable_video', e.target.checked);

      await api.put('/users', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        '',
        `Video ${e.target.checked ? 'desabilitado' : 'hábilitado'}`
      );
      setEnableVideo(!enable_video);
    } catch (err) {
      toast.error('', `${err.message}`);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('title', title);
    formData.append('custom_slug', custom_link);
    formData.append('description', description);
    formData.append('facebook', facebook != undefined ? facebook : '');
    formData.append('instagram', instagram !== undefined ? instagram : '');
    formData.append('twitter', twitter !== undefined ? twitter : '');
    formData.append('youtube', youtube !== undefined ? youtube : '');
    formData.append('twitch', twitch !== undefined ? twitch : '');
    formData.append('tiktok', tiktok !== undefined ? tiktok : '');
    formData.append('website', website !== undefined ? website : '');
    formData.append('whatsapp', whatsapp !== undefined ? whatsapp : '');
    formData.append('category', category);
    formData.append('file', file);
    formData.append('password', senha);

    toast.success('', 'Aguarde...');
    await api
      .put('/users', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success('', 'Seus dados foram atualizados');
        dispatch(updateProfileSuccess(res.data.user));
      })
      .catch((err) => {
        if (err.response) {
          toast.error('', `${err.response.data.description}`);
        } else {
          toast.error('', `${err.message}`);
        }
      })
      .then((response) => {
        // history.push("/papos");
      })
      .catch((err) => {
        if (err.response) {
          toast.error('', `${err.response.data.description}`);
        } else {
          toast.error('', `${err.message}`);
        }
      });
  }

  async function handleRecorderUploading(embedding) {
    try {
      const formData = new FormData();
      formData.append('video_token', embedding.video);
      formData.append('enable_video', JSON.stringify(true));
      setEnableVideo(true);

      await api.put('/users', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
      toast.error('', `Tivemos um problema, tente novamente!`);
    }
    console.log('Recorder uploading');
    console.log(embedding);
  }

  function handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      coverRef.current.src = reader.result;
    };

    reader.readAsDataURL(file);
  }

  return (
    <Container>
      <iframe src={`https://papovip.sak.com.br/faq?q=`} title="Papovip-faq" style={{ border: 'none', width: '100%', height: '120vh' }} frameBorder={0}></iframe>
      {/* <iframe src="https://WAK2ocOX0.sak.com.br/faq?q=" style="border:none; width: 100%; height:80vh;" frameborder="0"></iframe> */}
      
      <ContainerCards>
        <Card background="#20D489" color="white" headerText="Atendimento" mainText="Suporte via Whatsapp" btnText="Ir para Whatsapp" btnAction="javascript:sak_toggle_widget()" />
        <Card background="#ffcc00" color="#5e0a07" headerText="Canais" mainText="Suporte Fórum" btnText="Ir para fórum" btnAction="https://forum.empreender.com.br/t/papo-vip" />
      </ContainerCards>
    </Container>
  );
}

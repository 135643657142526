import styled from "styled-components";

export const Nav = styled.ul`
  li:hover .triangle {
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-color: transparent transparent #639;
    border-style: solid;
    border-width: 0 0 30px 30px;
  }

  li a.active + .triangle {
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-color: transparent transparent #639;
    border-style: solid;
    border-width: 0 0 30px 30px;
  }

  li a:hover,
  li a.active {
    color: #639 !important;
  }
`;

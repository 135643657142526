// export function getDateTimeRequest(data) {
//   return {
//     type: '@users/GET_DATETIME_REQUEST',
//     payload: { data },
//   };
// }

// export function getDateTimeSuccess(data) {
//   return {
//     type: '@users/GET_DATETIME_SUCCESS',
//     payload: { data },
//   };
// }

// export function getDateTimeError() {
//   return {
//     type: '@users/GET_DATETIME_ERROR',
//   };
// }

// export function sendPaymentRequest(data) {
//   return {
//     type: '@users/SEND_PAYMENT_REQUEST',
//     payload: data,
//   };
// }

export function sendPaymentSuccess() {
  return {
    type: '@users/SEND_PAYMENT_SUCCESS',
  };
}

export function sendPaymentError() {
  return {
    type: '@users/SEND_PAYMENT_ERROR',
  };
}

export function updateProfileSuccess(data) {
  return {
    type: '@users/UPDATE_PROFILE_SUCCESS',
    payload: { data },
  };
}

export function requestAuthGoogle(data) {
  return {
    type: '@users/AUTH_GOOGLE_REQUEST',
    payload: { data },
  };
}

export function requestAuthGoogleDisconnect() {
  return {
    type: '@users/AUTH_GOOGLE_DISCONNECT',
  };
}

export function requestAuthMP() {
  return {
    type: '@users/AUTH_MP_REQUEST',
  };
}

import React from "react";
import { Container, Background, Header, Footer } from "./styles";

export default function Error404() {
  return (
    <>
      <Background></Background>
      <Header className="top">
        <h1>404</h1>
        <h3>ERRO</h3>
      </Header>
      <Container className="container">
        <div className="ghost-copy">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
          <div className="four"></div>
        </div>
        <div className="ghost">
          <div className="face">
            <div className="eye"></div>
            <div className="eye-right"></div>
            <div className="mouth"></div>
          </div>
        </div>
        <div className="shadow"></div>
      </Container>
      <Footer className="bottom">
        <p>Boo, parece que estamos com algum problema!</p>
      </Footer>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  min-height: 100vh;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  .box-login {
    background: #fff;
    max-width: 950px;
    margin: 0 auto;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .login .bg-img {
    padding: 0;
    border-radius: 10px 0 0 10px;
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
  }
  .info {
    height: 464px;
    border-radius: 10px 0px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffde00;
    a {
      padding: 10px 0;
      font-size: 12px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-family: 'Roboto';
    }

    img {
      height: 200px;
      margin-top: 20px;
    }
  }
  .bg-img {
    padding: 0;
    border-radius: 10px 0 0 10px;
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const BoxForm = styled.div`
  max-width: 500px;
  flex: 1;
  padding: 10px;

  label {
    margin: 0.5em 0 0.5em;
    font-weight: 400;
    font-size: 15px;
    color: #363636;
    line-height: 1.5;
    float: left;
  }

  h1 {
    font-size: 1.6em;
    font-weight: 700;
  }

  p {
    font-size: 1.3em;
    color: #7a7a7a;
  }

  a {
    font-size: 1em;
    color: #5a00c9;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .bg {
    margin-top: 20px;
    /* background: #5a00c9 !important; */
    margin-right: 0;
    height: 47px;
    font-weight: 700;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    &:hover {
      background: #6a19ce !important;
      cursor: pointer;
    }
    &:focus {
      background: #6a19ce !important;
    }
  }

  .google {
    font-size: 18px;
    background: #ff108c !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 47px;
    padding: 0;
    border-radius: 8px;
    border: 0;
    &:hover {
      background: #fe2897 !important;
    }
    &:focus {
      background: #fe2897 !important;
    }
  }

  .forgot-link {
    color: #616161;
  }
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  button {
    background: none;
    border: 0;
    position: absolute;
    top: 8px;
    right: 5px;
    color: #6a19ce;
    font-size: 16px;
    &:focus {
      border: 0;
      outline: none;
    }
  }
`;

import { TitleModal, BodyModal, ItemModal, Option } from "./styled";
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AiOutlineLoading, AiFillCheckCircle, AiFillCaretRight } from "react-icons/ai";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { BsTools } from "react-icons/bs";
import React from "react";
import ImgNuvemshop from '~/assets/images/integrations/nuvemshop.png';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        zIndex: "99999999999999999999999999999999999999999999999999999999999999999999999"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "1000000000000000000000000000000000000000000000"
    }
};

const ModalComponent = (props) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const statusSync = useSelector(state => state.reducerSync)
    const statusIntegration = useSelector(state => state.integrations)
    const controlPlatform = useSelector(state => state.reducerControlPlatform)
    // const controlSyncProducts = useSelector(state => state.reducerControlSyncProducts)

    const dispatch = useDispatch()

    const getIconIntegration = () => {
        if (!statusIntegration?.loading && !statusIntegration?.error && statusIntegration?.data?.length == 0) {
            return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
        } else {
            if (statusIntegration?.loading === true) return <AiOutlineLoading size={20} color={"green"} />
            else {
                if (statusIntegration?.error === true) return <MdError size={20} color={"red"} />
                else return <AiFillCheckCircle size={20} color={"green"} />
            }
        }
    }

    const getIconSync = () => {
        if (!statusSync?.loading && !statusSync?.error && statusSync?.data.length == 0) {
            return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
        } else {
            if (statusSync?.loading == true) return <AiOutlineLoading size={20} color={"green"} />
            else {
                if (statusSync?.error === true) return <MdError size={20} color={"red"} />
                else return <AiFillCheckCircle size={20} color={"green"} />
            }
        }
    }

    useEffect(() => {
        if (controlPlatform.data.typeIntegration) {
            setModalIsOpen(true)
            dispatch({ type: "SAGA_ADD_INTEGRATION", payload: controlPlatform.data })
        }
    }, [])




    useEffect(() => {
        console.log("teste props modal".props)
    }, [])


    useEffect(() => {
        //console.log("update statusIntegration", statusIntegration)
        if (statusIntegration?.loading == false && statusIntegration?.error == false && statusIntegration?.data?.length == 0) {
            //alert("padrão")
        } else {
            console.log("teste statusIntegration", statusIntegration)
            if (statusIntegration?.loading) {
                //alert("loading")
            } else {
                if (statusIntegration?.error) {
                    //alert("erro")
                    //toast.error('Erro ao tentar integração.');
                    setModalIsOpen(false)
                } else {
                    //alert("sucsess")
                    if (typeof statusIntegration != "undefined") {
                        //alert("sucsess")
                        //toast.success('Integrado com sucesso.');
                        setModalIsOpen(false)
                    }

                }
            }
        }
    }, [statusIntegration])


    return (
        <Modal
            isOpen={modalIsOpen}
            //onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            ariaHideApp={false}
        >
            <TitleModal>
                <span>
                    < img src={ImgNuvemshop} />
                </span>
                <label>Nuvemshop</label>
            </TitleModal>
            <BodyModal>
                <Option>
                    <label>Ação</label>
                    <label>Progresso</label>
                    <div>Feedback</div>
                </Option>
                <Option
                // loading={statusIntegration.loading}
                >
                    <label>Integração</label>
                    <label>1 de 1</label>
                    <div>
                        <span>
                            <AiOutlineLoading size={20} color={"green"} />
                            {/* {getIconIntegration()} */}
                        </span>
                    </div>
                </Option>

            </BodyModal>
        </Modal>
    )
}

export default ModalComponent

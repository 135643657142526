import styled from "styled-components";

export const TitleModal = styled.div`
    display: flex;
    height: 75px;
    justify-content: center;
    align-items: center;
    width: 500px;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0px;
    //background-color: purple;
    z-index: 9999999999999999999999999999999999999999999999999999999 ;

    label{
        margin-left: -45px;
        display: flex ;
        flex: 1 ;
        //background-color: yellow ;
        justify-content: center ;
        font-size: 22px ;
        font-weight: bold ;

    }

    svg{
        margin-left: 20px;
    }
    span{
        display: flex;
        //flex: 1 ;

        img{
            height: 25px;
            position: absolute ;
            top: 0 ;
            margin-top: 25px;
            margin-left: 20px ;
        }
    }

`;

export const BodyModal = styled.div`
    display: flex;
    flex-direction: column;

    margin: 0px 20px;
`;

export const ItemModal = styled.div`
    display: flex;
    height: 40px;
    align-items: center;


    span{
        display: flex;
        justify-content: center;
        align-items: center;
        animation: ${props => props.loading && "spin 0.5s linear infinite"};

        @keyframes spin { 
            100% { 
                transform:rotate(360deg); 
            } 
        }
    }

    label{
        margin-left: 10px;
    }
`;

export const Action = styled.div`
    display: flex;
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 10px;

    span{
        display: flex;
        background-color: ${props => props.theme.colors.font.active};
        width: 100px;
        height: 30px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;

        :hover{
            opacity: 0.8;
        }

        label{
            font-size: 14px;
            color: white;
            cursor: pointer;
        }
    }

    span:nth-child(1){
        background-color: transparent;
        border: 2px solid gray;
        
        label{
            color: gray;
            font-weight: bold;
        }
    }

`;

export const Option = styled.div`
    //background-color: red ;
    display: flex ;
    justify-content: space-between ;
    height: 45px ;
    align-items: center ;
    border-top: 1px solid #F1F1F1 ;
    font-size: 14px;

    :nth-child(1){
        //background-color: red;
        border-top: 0px solid #F1F1F1 ;
    }

    div{
        //background-color: red ;
        height: 40px ;
        display: flex ;
        align-items: center ;
        span{
            //background-color: yellow ;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: spin 0.5s linear infinite;
            //background-color: yellow ;

            @keyframes spin { 
                100% { 
                    transform:rotate(360deg); 
                } 
            }
        }
    }

    

    :nth-child(3){
        //background-color: yellow;
        border-bottom: 1px solid #F1F1F1 ;
    }
    

    label:nth-child(1){
        display: flex ;
        flex: 1 ;

    }

    label:nth-child(2){
        width: 100px ;

    }
    div:nth-child(3){
        width: 100px ;
        display: flex ;
        justify-content: flex-end ;

       
    }
`

import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Nuvemshop = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    console.log("teste code", urlParams.get("code"))

    localStorage.setItem("papo@is_nuvem", true);

    dispatch({
      type: "CONTROL_PLATFORM_SUCCESS", payload: {
        typeIntegration: "nuvemshop",
        code: urlParams.get("code"),
      }
    })

    history.push('/minhaconta');

  }, [])

  return (
    <div ></div>
  )
}

export default Nuvemshop
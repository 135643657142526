import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '~services/api';
import { FaEye, FaEyeSlash, FaFacebook, FaGoogle } from 'react-icons/fa';
import { NotificationManager as toast } from 'react-notifications';
import { Form, FormGroup } from './styles';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { signUpSuccess, signInSuccess, signUpRequest, signUpWithPlusRequest } from '~/store/modules/auth/actions';
import { updateProfileSuccess } from '~/store/modules/users/actions';
import { getCookieFromString } from '~/helpers/cookies';

export default function SignUp() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const loading = useSelector((state) => state.auth.loading);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);

  const history = useHistory();

  // useEffect(() => {
  //   googleSDK();
  // }, []);

  function handleShowPassword() {
    setIsShowPassword(!isShowPassword);
  }

  function handleShowPasswordConfirm() {
    setIsShowPasswordConfirm(!isShowPasswordConfirm);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);

    if (formData.get('password') !== formData.get('confirmPassword')) {
      toast.error('Senhas não conferem!', 'Atenção');
    } else {
      if (process.env.REACT_APP_HABILITAR_PLUS) {
        dispatch(signUpWithPlusRequest(Object.fromEntries(formData.entries())));
      } else {
        dispatch(signUpRequest(formData));
      }
    }
  }

  // function googleSDK() {
  //   window['googleSDKLoaded'] = () => {
  //     window['gapi'].load('auth2', () => {
  //       //this.auth2 = window['gapi'].auth2;
  //     });
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, 'script', 'google-jssdk');
  // }

  // async function prepareLoginButton() {
  //   window['gapi'].auth2.authorize(
  //     {
  //       response_type: 'code id_token permission',
  //       client_id: process.env.REACT_APP_CLIENT_ID_GOOGLE,
  //       scope: process.env.REACT_APP_SCOPE_GOOGLE,
  //     },
  //     async (responseAuthorize) => {
  //       try {
  //         if (responseAuthorize.error) {
  //           console.log(responseAuthorize.error);
  //           return toast.error('', 'Falha no login com o google');
  //         }

  //         let base64Url = responseAuthorize.id_token.split('.')[1]; // token you get
  //         let base64 = base64Url.replace('-', '+').replace(' ', '/');
  //         let id_payload = JSON.parse(
  //           Buffer.from(base64, 'base64').toString('binary')
  //         );
  //         const urlBackend = '/login/google';

  //         const responseBackend = await api.post(urlBackend, {
  //           code: responseAuthorize.code,
  //           data: {
  //             username: id_payload.email,
  //           },
  //         });

  //         localStorage.setItem('token', responseBackend.data.token);
  //         dispatch(signUpSuccess(responseBackend.data.user));
  //         history.push('/papos');
  //       } catch (err) {
  //         console.log('Error in auth google');
  //         return toast.error('', 'Falha no login com o google');
  //       }
  //     }
  //   );
  // }


  let utm_source = getCookieFromString('utm_source');

  let utm_campaign = getCookieFromString('utm_campaign');

  let utm_medium = getCookieFromString('utm_medium');

  return (
    <>
      <h1>
        Faça seu cadastro e tenha <br />
        acesso ao papo.vip
      </h1>
      <p>
        Já possui uma conta? <Link to={'/'}>Acessar</Link>
      </p>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <input
            type="text"
            className="form-control"
            onChange={(event) => setName(event.target.value)}
            required
            name="name"
            placeholder="digite seu nome"
          />
          <i className="flaticon-account"></i>
        </FormGroup>
        <FormGroup>
          <input
            type="email"
            className="form-control"
            onChange={(event) => setUsername(event.target.value)}
            required
            name="username"
            aria-describedby="emailHelp"
            placeholder="email@example.com"
          />
          <i className="flaticon-mail-2"></i>
        </FormGroup>

        <FormGroup>
          <input
            type={isShowPassword ? 'text' : 'password'}
            className="form-control"
            required
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            placeholder="password"
            data-toggle="password"
          />
          <i className="flaticon-password"></i>

          <button type="button" onClick={handleShowPassword}>
            {isShowPassword ? (
              <FaEyeSlash color="#616161" />
            ) : (
              <FaEye color="#616161" />
            )}
          </button>
        </FormGroup>

        <FormGroup>
          <input
            type={isShowPasswordConfirm ? 'text' : 'password'}
            className="form-control"
            required
            onChange={(event) => setConfirmPassword(event.target.value)}
            name="password"
            placeholder="password"
            data-toggle="password"
          />
          <i className="flaticon-password"></i>

          <button type="button" onClick={handleShowPasswordConfirm}>
            {isShowPasswordConfirm ? (
              <FaEyeSlash color="#616161" />
            ) : (
              <FaEye color="#616161" />
            )}
          </button>
        </FormGroup>

        <button type="submit" className="btn-md mt-0 btn-submit">
          {loading ? (
            <AiOutlineLoading3Quarters className="spin" size={30} />
          ) : (
            ' Registrar'
          )}
        </button>

        <div className="extra-login clearfix">
          <span>Ou Acesse com</span>
        </div>

        <ul className="social-list">
          <li>
            <a href="#" className="facebook-bg" onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_BASE_URL + `/eplus/auth/login-social/facebook?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                window.location.href = linkToRedirect;
              }
            }}>
              <span className="facebook-i">
                <FaFacebook className="fab fa-facebook " size="1em" color="#fff" />
              </span>
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a href="#" className="google-bg" onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_BASE_URL + `/eplus/auth/login-social/google?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                window.location.href = linkToRedirect;
              }
            }}>
              <span className="google-i">
                <FaGoogle className="fab fa-google " size="1em" color="#fff" />
              </span>
              <span>Google</span>
            </a>
          </li>
        </ul>
      </Form>
    </>
  );
}

const SignupSchema = yup.object().shape({
  username: yup.string().required('email is required'),
  email: yup.string().email('Invalid email').required('email is required'),
  password: yup
    .string()
    .min(8, 'Password must be 8 character long')
    .required('password is required'),
  repassword: yup
    .string()
    .required('repeat password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) =>
      props.color ? '#fff' : 'linear-gradient(-45deg, #fff300, #fff300)'};
  }
`;

export default GlobalStyle;

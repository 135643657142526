import { combineReducers } from 'redux';
// user-org
import auth from './auth/reducer';
import users from './users/reducer';
import theme from './theme/reducer';
import cupons from './cupons/reducer';
import integrations from './integrations/reducerIntegration';
import reducerDeleteIntegration from './integrations/reducerDeleteIntegration';
import reducerControlPlatform from './integrations/reducerControlPlatform';
import storage from 'redux-persist/lib/storage'; 

const appReducer = combineReducers({
  auth,
  users,
  cupons,
  theme,
  integrations,
  reducerDeleteIntegration,
  reducerControlPlatform,
});

const rootReducer = (state, action) => {
  if (action.type === '@auth/SIGN_OUT') {
    storage.removeItem('persist:PAPOVIP-PANEL');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import produce from "immer";

const INITIAL_STATE = {
  cupons: [],
  loading: false,
  cupom: null,
};

export default function cupons(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@cupons/NEW_CUPOM_REQUEST":
        draft.loading = true;
        break;
      case "@cupons/NEW_CUPOM_SUCCESS":
        draft.loading = false;
        break;
      case "@cupons/NEW_CUPOM_ERROR":
        draft.loading = false;
        break;
      case "@cupons/LOAD_CUPONS_REQUEST":
        draft.loading = true;
        break;
      case "@cupons/LOAD_CUPONS_SUCCESS":
        draft.cupons = action.payload.cupons;
        draft.loading = false;
        break;
      case "@cupons/LOAD_CUPONS_ERROR":
        draft.loading = false;
        break;
      case "@cupons/DELETE_CUPONS_REQUEST":
        const productIndex = draft.cupons.findIndex(
          (p) => p.id === action.payload.id
        );
        if (productIndex >= 0) {
          draft.cupons.splice(productIndex, 1);
        }
        break;
      default:
        return state;
    }
  });
}

import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager as toast } from "react-notifications";
// import {
//   PapoText,
//   Time,
//   Minutes,
//   CardFooter,
//   PapoActive,
//   DropdownIcon,
//   PapoName,
// } from "./styles";
import moment from "moment";
import swal from "sweetalert2";

import {
  loadCuponsRequest,
  deleteCuponsRequest,
} from "~/store/modules/cupons/actions";

export default function Home() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.cupons.loading);
  const cuponsList = useSelector((state) => state.cupons.cupons);

  useEffect(() => {
    dispatch(loadCuponsRequest());
  }, []);

  function handleDeleteCupom({ id, name }) {
    swal
      .fire({
        text: "Tem certeza que deseja deletar este cupom?",
        icon: "warning",
        buttons: true,
        confirmButtonText: "Deletar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        dangerMode: true,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          dispatch(deleteCuponsRequest(id));
        }
      });
  }

  function isExpiredDate(date, now) {
    const dateNow = parseInt(moment(now).format("X"), 10);
    return dateNow >= date;
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-auto ml-auto text-right">
          <Link to={`${path}/create`} className="btn btn-primary btn-lg">
            Criar Cupom
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {loading ? (
            <div className="text-center">
              <div className="spinner spinner-primary mr-3"></div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                {cuponsList.length < 1 ? (
                  <p className="text-center">
                    Você não possui nenhum cupom cadastrado no momento!
                  </p>
                ) : (
                  <div className="table-responsive">
                    <table
                      id="user_table"
                      className="table table-hover text-center"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Código</th>
                          <th scope="col">Descrição</th>
                          <th scope="col">Status</th>
                          <th scope="col">Utilizados</th>
                          <th scope="col">Data de Expiração</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cuponsList.map(
                          ({
                            id,
                            value,
                            name,
                            valid_until,
                            is_limited,
                            limit,
                            total,
                          }) => (
                            <tr key={id}>
                              <td className="text-uppercase">{name}</td>
                              <td>{value}% de desconto</td>

                              <td>
                                {isExpiredDate(valid_until, new Date()) ? (
                                  <span className={`badge badge-light`}>
                                    Expirado
                                  </span>
                                ) : (
                                  <span className={`badge badge-success`}>
                                    Disponível
                                  </span>
                                )}
                              </td>
                              <td>
                                {limit} de {total}
                              </td>
                              <td>
                                {moment
                                  .unix(valid_until)
                                  .format("DD/MM/YYYY H:mm")}
                              </td>
                              <td>
                                <Link
                                  to={`${path}/edit/${id}`}
                                  className="cursor-pointer text-success mr-2"
                                >
                                  <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                </Link>
                                <span
                                  onClick={() =>
                                    handleDeleteCupom({ id, name })
                                  }
                                  className="cursor-pointer text-danger mr-2"
                                >
                                  <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Breadcrumb } from '@gull';

import { NotificationManager as toast } from 'react-notifications';
import { BoxForm, BtnCancelar } from './styles';
import moment from 'moment';
import api from '~/services/api';
import history from '~/services/history';
import { useParams } from 'react-router-dom';

export default function Edit() {
  const empty_papo = {
    value: 0,
    time: 0,
    custom_field_value: '',
    show_custom_value: '',
    availability_start: '00:00',
    availability_end: '00:00',
    available_days: {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
    active: true,
  };

  const [edit_papo, setEditPapo] = useState(empty_papo);
  const [papos, setPapos] = useState({});
  const [userData, setUserData] = useState({});
  const [getLoading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const { id } = useParams();

  useEffect(() => {
    async function fetchAPI() {
      const response = await api.get(`papos/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPapos((prevState) => ({
        ...prevState,
        ...response.data,
        available_days: JSON.parse(response.data.available_days),
      }));
      setLoading(false);
    }
    fetchAPI();
  }, []);

  function handleChange(e) {
    e.persist();
    setPapos((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  // function handleEditPapo(e) {
  //   copy[e.target.name] = e.target.value;
  //   setEditPapo(copy);
  // }

  function handleCheckboxDays(e) {
    e.persist();
    let copy = Object.assign({}, edit_papo);
    copy.available_days[e.target.name] = e.target.checked;

    const day = (papos['available_days'][e.target.name] = e.target.checked);
    setPapos((prevState) => ({
      ...prevState,
      ...papos,
      day,
    }));
  }

  function handleAvailabilityStart(time) {
    if (time) {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = moment(time._d).format('HH:mm');
      setEditPapo(copy);
    } else {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = '00:00';
      setEditPapo(copy);
    }
  }

  function handleAvailabilityEnd(time) {
    if (time) {
      let copy = Object.assign({}, edit_papo);
      copy.availability_end = moment(time._d).format('HH:mm');
      setEditPapo(copy);
    } else {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = '00:00';
      setEditPapo(copy);
    }
  }

  async function handleSavePapo(e) {
    e.preventDefault();
    toast.success('Aguarde...');

    if (papos.time <= 0) {
      toast.error('A duração deve ser maior que zero');
      return;
    }

    if (
      papos.availability_start === '00:00' &&
      papos.availability_end === '00:00'
    ) {
      toast.error('Horário disponível não preenchido');
      return;
    }

    if (papos.availability_start > papos.availability_end) {
      toast.error('O horário inicial não pode ser maior que o final');
      return;
    }

    try {
      await api.put(`papos/${id}`, papos, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Papo atualizado!');
      history.push('/papos');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao criar papo!');
    }
  }

  function handleCheckBoxShowCustomField(e) {
    e.persist();
    setPapos((prevState) => ({
      ...prevState,
      ...papos,
      show_custom_field: e.target.checked,
    }));
  }

  return (
    <>
      {getLoading ? (
        <div className="row">
          <div className="col text-center">
            <div className="spinner spinner-primary mr-3"></div>
          </div>
        </div>
      ) : (
        <div>
          <div className="card">
            <div className="card-header">Editar Papo</div>
            <div className="card-body">
              <BoxForm>
                <form className="form" onSubmit={handleSavePapo}>
                  <div className="div-input">
                    <label htmlFor="time-duration">Duração do Papo</label>
                    <div>
                      <input
                        type="number"
                        required
                        onChange={handleChange}
                        className="form-control"
                        name="time"
                        id="time-duration"
                        placeholder="Ex: 30"
                        value={papos.time}
                      />
                      <span>Minutos</span>
                    </div>
                  </div>

                  {/* <div className="div-input">
                    <label htmlFor="papo-value">Valor do Papo</label>
                    <div>
                      <input
                        type="number"
                        required
                        onChange={handleChange}
                        className="form-control"
                        name="value"
                        id="papo-value"
                        placeholder="Ex: 89.99"
                        value={papos.value}
                      />
                      <span>Reais</span>
                    </div>
                  </div> */}

                  <div className="div-input">
                    <label htmlFor="interval_between_papos">
                      Intervalo Antes do Papo
                    </label>
                    <div>
                      <input
                        type="number"
                        required
                        onChange={handleChange}
                        min="0"
                        className="form-control"
                        name="interval_between_papos"
                        id="interval_between_papos"
                        placeholder="Ex: 30"
                        value={papos.interval_between_papos}
                      />
                      <span>Minutos</span>
                    </div>
                  </div>
                  <div className="div-input">
                    <label htmlFor="minimum_time_start_papos">
                      Tempo Mínimo Para Agendar o Papo
                    </label>
                    <div>
                      <input
                        type="number"
                        required
                        onChange={handleChange}
                        min="15"
                        className="form-control"
                        name="minimum_time_start_papos"
                        id="minimum_time_start_papos"
                        placeholder="Ex: 60"
                        value={papos.minimum_time_start_papos}
                      />
                      <span>Minutos</span>
                    </div>
                  </div>

                  <h2>Disponibilidade</h2>

                  <div className="div-days">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="monday"
                        id="monday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['monday']}
                        className="form-check-input"
                        value="monday"
                      />
                      <label htmlFor="monday" className="form-check-label">
                        Segunda
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="tuesday"
                        id="tuesday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['tuesday']}
                        className="form-check-input"
                      />
                      <label htmlFor="tuesday" className="form-check-label">
                        Terça
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="wednesday"
                        id="wednesday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['wednesday']}
                        className="form-check-input"
                      />
                      <label htmlFor="wednesday" className="form-check-label">
                        Quarta
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="thursday"
                        id="thursday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['thursday']}
                        className="form-check-input"
                      />
                      <label htmlFor="thursday" className="form-check-label">
                        Quinta
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="friday"
                        id="friday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['friday']}
                        className="form-check-input"
                      />
                      <label htmlFor="friday" className="form-check-label">
                        Sexta
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="saturday"
                        id="saturday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['saturday']}
                        className="form-check-input"
                      />
                      <label htmlFor="saturday" className="form-check-label">
                        Sábado
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="sunday"
                        id="sunday"
                        onChange={handleCheckboxDays}
                        checked={papos.available_days['sunday']}
                        className="form-check-input"
                      />
                      <label htmlFor="sunday" className="form-check-label">
                        Domingo
                      </label>
                    </div>
                  </div>

                  <div className="div-time-pickers">
                    <h2>Horário disponível</h2>
                    <div className="form-group">
                      <input
                        type="time"
                        min="00:00"
                        max="23:59"
                        value={papos.availability_start}
                        required
                        name="availability_start"
                        onChange={handleChange}
                        //   value={moment(edit_papo.availability_start, "HH:mm")}
                        format={'HH:mm'}
                      />
                      <span> Até </span>
                      <input
                        type="time"
                        min="00:00"
                        max="23:59"
                        value={papos.availability_end}
                        required
                        name="availability_end"
                        onChange={handleChange}
                        //   value={moment(edit_papo.availability_end, "HH:mm")}
                        format={'HH:mm'}
                        className="timePicker"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label
                        data-toggle="tooltip"
                        title="A resposta desse campo será anexada ao evento criado no calendário"
                        htmlFor="custom_field_value"
                      >
                        Ex: Sobre o que deseja falar?
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          onChange={handleChange}
                          className="form-control mr-2"
                          name="custom_field_value"
                          id="custom_field_value"
                          value={papos.custom_field_value}
                        />
                        <input
                          type="checkbox"
                          name="show_custom_field"
                          onChange={handleCheckBoxShowCustomField}
                          id="show_custom_field"
                          checked={papos.show_custom_field}
                          className="mr-1"
                        />
                        <label className="m-0" htmlFor="show_custom_field">
                          Exibir
                        </label>
                      </div>
                      <span>
                        A resposta desse campo será anexada ao evento criado no
                        calendário
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center mt-4">
                    <BtnCancelar
                      to="/papos"
                      className="btn-raised btn-raised-light mr-4 text-capitalize btn btn-light"
                    >
                      Cancelar
                    </BtnCancelar>
                    <button type="submit" className="btn btn-primary btn-save">
                      Atualizar
                    </button>
                  </div>
                </form>
              </BoxForm>
            </div>
          </div>
        </div>
      )}
      {/* <NotificationContainer /> */}
    </>
  );
}

import styled from "styled-components";
import { Form } from "react-bootstrap";

export const PapoName = styled.div`
  display: flex;
  align-items: end;

  span {
    color: #09f;
    cursor: pointer;
  }

  button {
    font-size: 1rem;
    background: none;
    border: none;
    line-height: 0;
    padding: 0;
    margin-left: 5px;
  }
`;

export const PapoText = styled.div`
  margin-bottom: 0px;
`;
export const Time = styled.h2`
  font-size: 4rem;
`;
export const Minutes = styled.p`
  margin-bottom: 0;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e9e9;
  padding: 10px 1.25rem;

  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

export const PapoActive = styled(Form.Check)`
  position: absolute;
  top: 10px;
  right: 0;
`;

export const DropdownIcon = styled.i`
  font-size: 1.5rem;
  color: #333;
`;

import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb } from '@gull';
import { useSelector } from 'react-redux';
import { NotificationManager as toast } from 'react-notifications';
import {
  PapoText,
  Time,
  Minutes,
  CardFooter,
  PapoActive,
  DropdownIcon,
  PapoName,
} from './styles';
import { Dropdown } from 'react-bootstrap';

import api from '~/services/api';
import swal from 'sweetalert2';

export default function Home() {
  const { path } = useRouteMatch();
  const [papos, setPapos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const custom_slug = useSelector((state) => state.users.profile.custom_slug);
  const name = useSelector((state) => state.users.profile.name);

  const token = localStorage.getItem('token');

  useEffect(() => {
    async function fetchAPI() {
      const response = await api.get('papos');
      setPapos(response.data.papos);
      setUserData(response.data.user);
      setLoading(false);
    }
    fetchAPI();
  }, []);

  function copiarLink(e, papo) {
    e.preventDefault();
    let inputTest = document.createElement('input');
    inputTest.value = `${process.env.REACT_APP_PAPO_URL}/${userData.custom_slug}/${papo.id}`;
    document.body.appendChild(inputTest);
    inputTest.select();
    document.execCommand('copy');
    document.body.removeChild(inputTest);
    toast.success('', 'URL copiada para área de transferência!');
  }

  function copiarLinkUser(e, papo) {
    e.preventDefault();
    let inputTest = document.createElement('input');
    inputTest.value = `papo.vip/${custom_slug}/${papos[0].id}`;
    document.body.appendChild(inputTest);
    inputTest.select();
    document.execCommand('copy');
    document.body.removeChild(inputTest);
    toast.success('', 'URL copiada para área de transferência!');
  }

  function deletarPapo(e, papo) {
    e.preventDefault();
    swal
      .fire({
        text: 'Tem certeza que deseja deletar este papo?',
        icon: 'warning',
        buttons: true,
        confirmButtonText: 'Deletar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        dangerMode: true,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`papos/${papo.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setPapos(papos.filter((p) => p.id !== papo.id));
            toast.success('', 'Papo deletado com sucesso!');
          } catch (err) {
            toast.error('', 'Erro ao deletar papo, tente novamente');
          }
        }
      });
  }

  async function duplicarPapo(e, papo) {
    try {
      const response = await api.post('papos', papo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPapos([...papos, response.data]);
      toast.success('', 'Papo duplicado');
    } catch (err) {
      toast.error('', 'Erro ao criar papo, tente novamente.');
    }
  }

  async function handleToggle(papo) {
    swal
      .fire({
        text: 'Deseja mesmo alterar a visibilidade deste Papo?',
        icon: 'warning',
        buttons: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        dangerMode: true,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.put(
              `papos/${papo.id}`,
              { active: !papo.active },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setPapos((teste) => {
              papos.map((papoMap) => {
                if (papoMap.id === papo.id) {
                  papoMap.active = !papo.active;
                }
              });
              teste = papos;
              return teste;
            });

            document.getElementById(papo.id).checked = papo.active;
            toast.success(
              '',
              `${papo.active ? 'Papo habilitado' : 'Papo desabiitado'}`
            );
          } catch (err) {
            console.log(err);
            toast.error('', 'Erro ao alterar visiblidade do papo!');
          }
        }
      });
  }

  function handleOpenPapoUser() {
    window.open(`https://www.papo.vip/${custom_slug}/${papos[0].id}`, '_blank');
  }

  return (
    <>
      <div>
        <div className="row justify-content-between mb-4">
          <div className="col-md-4">
            <h4>{name}</h4>
            {papos.length > 0 && (
              <PapoName className="text-info">
                <span onClick={handleOpenPapoUser}>papo.vip/{custom_slug}</span>
                <button type="button" onClick={copiarLinkUser}>
                  <i className="i-File-Copy"></i>
                </button>
              </PapoName>
            )}
          </div>

          <div className="col-md-4 text-right">
            <Link to={`${path}/create`} className="btn btn-primary btn-lg">
              Criar Novo
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        {loading ? (
          <div className="col">
            <div className="text-center">
              <div className="spinner spinner-primary mr-3"></div>
            </div>
          </div>
        ) : !papos.length ? (
          <div className="col">
            <div className="card">
              <div className="card-body">
                <p className="text-center">
                  Você não possui nenhum papo cadastrado no momento!
                </p>
              </div>
            </div>
          </div>
        ) : (
          papos.map((papo) => (
            <div key={papo.id} className="col-lg-3 col-md-3 mt-4 text-center">
              <div className="card">
                <div className="card-body position-relative">
                  <PapoActive
                    label=""
                    type="switch"
                    id={papo.id}
                    onChange={() => handleToggle(papo)}
                    checked={!!papo.active}
                  />
                  <div className="ul-product-detail__border-box">
                    <div className="ul-product-detail--icon mb-2">
                      <PapoText>Papo.vip de</PapoText>
                    </div>
                    <Time>
                      <a
                        href={`https://papo.vip/${custom_slug}/${papo.id}`}
                        target="_blank"
                      >
                        {papo.time}
                      </a>
                    </Time>
                    <Minutes>Minutos</Minutes>
                  </div>
                </div>
                <CardFooter>
                  <span>
                    {/* {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(papo.value)} */}
                    <label>Configurações</label>
                  </span>
                  <Dropdown alignRight drop="down">
                    <Dropdown.Toggle className="toggle-hidden border-0 bg-white d-flex flex-column shadow-none">
                      <DropdownIcon className="i-Gear spin"></DropdownIcon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => copiarLink(e, papo)}>
                        Copiar Link
                      </Dropdown.Item>
                      <Link
                        className="dropdown-item"
                        to={`${path}/edit/${papo.id}`}
                      >
                        Editar
                      </Link>

                      <Dropdown.Item onClick={(e) => duplicarPapo(e, papo)}>
                        Duplicar
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => deletarPapo(e, papo)}>
                        Deletar
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </CardFooter>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  .ba-videorecorder-container {
    max-width: 100%;
  }
  .card {
    box-shadow: 0 0px 2px 1px rgb(0 0 0 / 3%), 0 1px 3px rgb(0 0 0 / 6%);
  }
`;
export const Input = styled(InputMask)``;
export const IntegracoesActive = styled(Form.Check)`
  position: absolute;
  top: 10px;
  right: 0;
`;

export const LabelFile = styled.label`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonViewPassword = styled.button`
  background: none;
  border: 0;
  position: absolute;
  top: 30px;
  right: 5px;
  &:focus {
    border: 0;
    outline: none;
  }
`;

export const AskPapo = styled.a`
  position: absolute;
  top: 0px;
  right: 7px;
  font-size: 26px;
`;

export const AvatarProfile = styled.label`
  position: relative;
  width: 80px;
  display: grid;
  grid-column: auto / auto;
  &:hover {
    cursor: pointer;
    .hide {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  .custom-file-upload {
    position: relative;
    display: grid;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .avatar {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    overflow: hidden;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(195, 195, 195);
    border-image: initial;
    border-radius: 50%;
  }

  .avatar .preview-text {
    font-size: 50px;
    color: rgb(102, 51, 153);
  }

  .avatar i.i-Camera {
    color: rgb(255, 255, 255);
    padding-top: 18px;
    position: absolute;
    z-index: 2;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 35px !important;
    background: rgba(0, 0, 0, 0.5);
    &.hide {
      visibility: hidden;
      opacity: 0.5;
      transition: opacity 0.5s ease-out 0s;
    }
  }
`;

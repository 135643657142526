import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { classList } from '@utils';
import Loading from '@gull/components/GullLoadable/Loading';

import Header from '~/components/Header';
import Sidebar from '~/components/Sidebar';

export default function DefaultLayout({ children }) {
  const open = useSelector((state) => state.theme.menu);

  return (
    <div>
      <div className={`app-admin-wrap layout-sidebar-large`}>
        <Header />
        <Sidebar />
        {/* sidebar */}

        <div
          className={classList({
            'main-content-wrap d-flex flex-column': true,
            'sidenav-open': open,
          })}
        >
          <Suspense fallback={<Loading />}>
            <div className="main-content">{children}</div>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '~/services/api';
import { useDispatch } from 'react-redux';

import { requestAuthGoogle } from '~/store/modules/users/actions';

function Auth() {
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    async function fetchAPI() {
      try {
        const code = (location.search.match(/code=([^&]+)/) || [])[1];
        const token = localStorage.getItem('token');

        await api
          .get(`/auth?code=${code}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((r) => {
            const emailGoogle = r && r.data && r.data.google_account;
            dispatch(
              requestAuthGoogle({
                is_google_auth: true,
                google_account: emailGoogle,
              })
            );
          });
        window.location.assign(`${process.env.REACT_APP_BASE_URL}/minhaconta`);
      } catch (err) {
        console.log(err);
      }
    }
    fetchAPI();
  }, [location]);

  return <p>Redirecionando...</p>;
}

export default Auth;

import React from 'react';
import PropTypes from 'prop-types';

import { Container, BoxForm } from './styles';
import mainLogo from '~/assets/images/logo.svg';

export default function AuthLayout({ children }) {
  return (
    <Container>
      <div className="container">
        <div className="row box-login">
          <div className="bg-img">
            <div className="info primary-color">
              <img src={mainLogo} />
              <a href="https://www.empreender.com.br" target="_blank">
                Empreender
              </a>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 bg-color-16 align-self-center">
            <BoxForm>{children}</BoxForm>
          </div>
        </div>
      </div>
    </Container>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

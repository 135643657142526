import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { navigations } from './navigations';
import { classList } from '@utils';
import Srcollbar from 'react-perfect-scrollbar';

import { toggleMenuRequest } from '~/store/modules/theme/actions';

import { Nav } from './styles';

export default function Sidebar() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.theme.menu);

  useEffect(() => {
    if (window.innerWidth < 1200) {
      dispatch(toggleMenuRequest());
    }
  }, []);

  useEffect(() => {
    var _support = _support || { ui: {}, user: {} };

    _support['account'] = 'depoimento';
    _support['ui']['contactMode'] = 'default';
    _support['ui']['enableKb'] = 'true';
    _support['ui']['styles'] = {
      widgetColor: 'rgb(2, 214, 156)',
    };
    _support['apps'] = {
      faq: { enabled: true },
      recentConversations: {},
      orders: {},
    };

    const scriptHelp = document.createElement('script');
    const scriptHelpINIT = document.createElement('script');

    scriptHelp.src = 'https://cdn.reamaze.com/assets/reamaze.js';
    document.getElementsByTagName('body')[0].appendChild(scriptHelp);
    document.getElementsByTagName('body')[0].appendChild(scriptHelpINIT);
  }, []);

  return (
    <div className="side-content-wrap">
      <Srcollbar
        className={classList({
          'sidebar-left o-hidden rtl-ps-none': true,
          open: open,
        })}
        // id="mainsidenav"
      >
        <Nav className="navigation-left">
          {navigations.map((item, i) => (
            <li
              className={classList({
                'nav-item': true,
              })}
              key={i}
            >
              <NavLink className="nav-item-hold" to={`/${item.url}`}>
                <i className={`nav-icon ${item.icon}`}></i>
                <span className="nav-text">{item.name}</span>
              </NavLink>

              <div className="triangle"></div>
            </li>
          ))}
          {/* <li
            className={classList({
              'nav-item': true,
            })}
          >
            <a
              href="#"
              className="nav-item-hold"
              data-reamaze-lightbox="kb"
              exact="true"
            >
              <i className="nav-icon i-Support"></i>
              <span className="nav-text">Ajuda</span>
              <div className="triangle"></div>
            </a>
          </li> */}

        </Nav>
      </Srcollbar>
    </div>
  );
}

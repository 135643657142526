import styled from 'styled-components';
import { device } from '~/styles/device';

export const Container = styled.div`
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  min-height: 100vh;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  .box-login {
    background: #fff;
    max-width: 950px;
    margin: 0 auto;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .info {
    min-height: 464px;
    border-radius: 10px 0px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #ffde00;
    a {
      padding: 10px 0;
      font-size: 12px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: 3px;
    }

    img {
      height: 200px;
      margin-top: 20px;
    }
  }
  .bg-img {
    padding: 0;
    border-radius: 10px 0 0 10px;
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    @media ${device.lg} {
      max-width: 380px;
    }
  }
`;

export const BoxForm = styled.div`
  max-width: 440px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-radius: 5px;

  form {
    align-items: start;
    input {
      float: left;
      width: 100%;
      padding: 11px 20px 11px 50px;
      border-radius: 50px;
      font-size: 14px;
      outline: none;
      background: #f7f7f7;
      color: #000;
      border: 1px solid #e8e8e8;
      height: 45px;
    }
    input + i {
      position: absolute;
      top: 12px;
      left: 20px;
      font-size: 19px;
    }
  }

  label {
    margin: 0.5em 0 0.5em;
    font-weight: 400;
    font-size: 15px;
    color: #363636;
    line-height: 1.5;
    float: left;
  }

  h1 {
    font-size: 1.6em;
    font-weight: 700;
  }

  p {
    font-size: 1.3em;
    color: #7a7a7a;
  }

  a {
    font-size: 1em;
    color: #5a00c9;
  }

  .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 10px;
      width: 100%;
      height: 1px;
      background: #d8dcdc;
      content: '';
    }

    span {
      width: auto;
      float: none;
      display: inline-block;
      background: #fff;
      padding: 1px 20px;
      z-index: 1;
      position: relative;
      font-size: 13px;
      color: #616161;
      text-transform: capitalize;
    }
  }

  .btn-submit {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 12px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
    color: #fff;
    background: #00a875;
    border: none;
    &:hover {
      background-color: #03c58a;
    }
  }

  .social-list {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .social-list li {
    display: inline-block;
    font-size: 16px;
  }

  .social-list li a {
    font-size: 12px;
    font-weight: 600;
    width: 120px;
    margin: 2px 0 3px 0;
    height: 35px;
    line-height: 35px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }

  .social-list li a span {
    margin-right: 7px;
  }
  .social-list li a span.google-i,
  .social-list li a span.facebook-i {
    height: 35px;
    width: 35px;
    line-height: 35px;
    float: left;
    border-radius: 20px;
  }

  .social-list .google-bg {
    background: #db4437;
    color: #fff;
  }

  .social-list .google-bg:hover {
    background: #f13525;
  }

  .social-list .google-i {
    background: #c3291c;
  }

  .social-list .google-bg:hover .google-i {
    background: #e02a1a;
  }

  .social-list .facebook-bg {
    background: #4867aa;
    color: #fff;
  }

  .social-list .facebook-bg:hover {
    background-color: #346ce4;
  }

  .social-list .facebook-i {
    background: #3b589e;;
  }

  .social-list .facebook-bg:hover .facebook-i {
    background: #437aff;
  }
`;

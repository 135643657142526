import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  profile: {},
  authGoogle: false,
  authMp: false,
  hours: [],
};

export default function users(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@users/GET_DATETIME_REQUEST':
        draft.loading = true;
        break;
      case '@users/GET_DATETIME_SUCCESS':
        draft.loading = false;
        draft.hours = action.payload.data;
        break;
      case '@users/GET_DATETIME_ERROR':
        draft.loading = false;
        break;
      case '@users/SEND_PAYMENT_REQUEST':
        draft.loading = true;
        break;
      case '@users/SEND_PAYMENT_SUCCESS':
        draft.loading = false;
        break;
      case '@users/SEND_PAYMENT_ERROR':
        draft.loading = false;
        break;
      case '@users/UPDATE_PROFILE_SUCCESS':
        draft.profile = { ...draft.profile, ...action.payload.data };
        break;
      case '@users/AUTH_GOOGLE_REQUEST': {
        draft.profile.google_account = action.payload.data.google_account;
        draft.profile.is_google_auth = action.payload.data.is_google_auth;
        break;
      }

      case '@users/AUTH_GOOGLE_DISCONNECT': {
        draft.profile.google_account = '';
        draft.profile.is_google_auth = false;
        draft.authGoogle = !draft.authGoogle;
        break;
      }

      case '@users/AUTH_MP_REQUEST': {
        draft.profile.is_mp_auth = !draft.authMp;
        draft.authMp = !draft.authMp;
        break;
      }

      default:
        return state;
    }
  });
}

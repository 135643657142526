import { Container, ButtonCustom, Close, Title, Item, ItemTitle, InputCustomShopify, Code, OptionsLabel } from "./styled";
import Modal from 'react-modal';
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
// import Alert from "../../../../components/Alert";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BiCopy } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { useEffect } from "react";
import React from "react";
import Alert from "~/components/Alert";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
        overflow: "auto",
        maxHeight: "90vh",
        zIndex: "100000000000000000000000000000000000"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "1000000000000000000000000000000000000000000000"
    }
};

const ModalIntegration = (props) => {

    const [inputStore, setInputStore] = useState("")
    const [inputUrlSite, setInputUrlSite] = useState("")
    const [inputClientId, setInputClientId] = useState("")
    const [inputClientSecret, setInputClientSecret] = useState("")
    const [inputBasic, setInputBasic] = useState("")
    const [tokenMontink, setTokenMontink] = useState("")
    const [chaveLojaIntegrada, setChaveLojaIntegrada] = useState("")
    const [tokenCartpanda, setTokenCartpanda] = useState("")

    const history = useHistory();

    return (
        <Modal
            isOpen={props.visible}
            onRequestClose={() => { props.setVisibleModal(false) }}
            style={customStyles}
            ariaHideApp={false}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}>
                    <GrFormClose size={25} color={"#5e6278"} />
                </Close>
                <Title>
                    <strong>Configuração Nuvemshop</strong>
                </Title>
                <ItemTitle>Passo a passo</ItemTitle>
                <Item>
                    1. Faça o login em sua conta da nuvemshop que deseja integrar.
                </Item>
                <Item>
                    2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Papovip novamente.)
                </Item>
                <Item>
                    3. Pronto sua loja já está integrada ao Papovip.
                </Item>
                <Alert marginBottom="0px" padding="0px 10px" background="red" >
                    <small style={{ fontSize: "11px" }}>Não deslogue do Papovip durante o processo de integração.</small>
                </Alert>
                <ButtonCustom onClick={() => { window.location = `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`}}>Conectar</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalIntegration

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Alert, Button } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { Link } from 'react-router-dom';
import avatar from '~/assets/images/avatar.png';
import AvatarUser from '~/assets/images/userIcon.svg';

import history from '~/services/history';
import logo from '~/assets/images/logo.svg';

import { signOut } from '~/store/modules/auth/actions';
import { toggleMenuRequest } from '~/store/modules/theme/actions';
import { FaRegCopy } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';

export default function Header() {
  const dispatch = useDispatch();
  const photo_url = useSelector((state) => state.users.profile.photo_url);
  const name = useSelector((state) => state.users.profile.name);
  const chave_empreender = useSelector((state) => state.users.profile.chave_empreender);
  const is_google_auth = useSelector(
    (state) => state.users.profile.is_google_auth
  );
  const is_mp_auth = useSelector((state) => state.users.profile.is_mp_auth);
  const [showCustomAlert, setShowCustomAlert] = useState(true);

  function closeCustomAlert() {
    setShowCustomAlert(false);
  }

  function handleLogout() {
    dispatch(signOut());
    localStorage.setItem('token', '');
    history.push('/login');
  }

  function handleToggleMenu() {
    dispatch(toggleMenuRequest());
  }
  useEffect(() => {
    console.log("teste avatar", photo_url)
  }, [])


  return (
    <div className="main-header">
      <div className="logo">
        <Link to="/papos">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div onClick={handleToggleMenu} className="menu-toggle d-xl-none">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div style={{ margin: 'auto' }}>
        {(!is_google_auth) && showCustomAlert ? (
          <Alert
            className="text-center alert-card m-0"
            variant="warning"
          // dismissible
          // onClose={() => closeCustomAlert()}
          >
            <span className="pt-2 pb-2">
              Complete a integração com o Google Calendar
            </span>
            {window.location.pathname !== '/minhaconta' && (
              <Button
                onClick={() => history.push('/minhaconta')}
                variant="warning"
                className="btn-rounded ml-3"
              >
                Clique aqui
              </Button>
            )}
          </Alert>
        ) : (
          ''
        )}
      </div>

      <div className="header-part-right">
        <div className="user col align-self-end">
          <Dropdown>
            <DropdownToggle as="span" className="toggle-hidden cursor-pointer">
              <img
                //src={photo_url || AvatarUser}
                src={photo_url == "https://empreender.nyc3.digitaloceanspaces.com/default-profile.png" ? avatar : photo_url}
                id="userDropdown"
                alt=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
            </DropdownToggle>
            <DropdownMenu>
              <div className="dropdown-header">
                <i className="i-Lock-User mr-1"></i> {name}
              </div>
              <div className="dropdown-item" onClick={() => {
                let inputChave = document.querySelector('.chaveCopiar');
                inputChave.focus();
                inputChave.select();
                document.execCommand('copy');
                NotificationManager.success("Chave empreender copiada!");
              }}>
                <b style={{ color: "#ff3685" }}>Chave Empreender:</b>
                <div style={{ display: "flex", alignItems: "center", width: "-moz-fit-content !important" }}>
                  <FaRegCopy style={{ marginRight: "8px", width: "16px", height: "16px", cursor: "pointer" }} />
                  <input type="text" className="chaveCopiar" style={{ border: "none", background: "transparent" }} readonly="true" value={chave_empreender} />
                </div>
              </div>
              <div className="dropdown-divider"></div>
              <Link to="/minhaconta" className="dropdown-item cursor-pointer">
                Minha Conta
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                to="/"
                className="dropdown-item cursor-pointer"
                onClick={handleLogout}
              >
                Sair
              </Link>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

import { all, call, takeLatest, put, select } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";
import history from "~/services/history";

import api from "~/services/api";

import {
  newCupomSuccess,
  newCupomError,
  loadCuponsSuccess,
  loadCuponsError,
  loadEditCuponsSuccess,
  loadEditCuponsError,
} from "./actions";

export function* newCupom({ payload }) {
  try {
    yield call(api.post, "/coupons", { ...payload, total: payload.limit });
    NotificationManager.success("", "Cupom criado com sucesso");
    yield put(newCupomSuccess());
    history.push("/cupons");
  } catch (err) {
    NotificationManager.error(
      "",
      "Erro ao tentar criar um cupom por favor verifique os dados!"
    );
    yield put(newCupomError());
  }
}

export function* loadCupons() {
  try {
    const response = yield call(api.get, "/coupons");
    yield put(loadCuponsSuccess(response.data));
  } catch (err) {
    NotificationManager.error("", "Erro ao tentar carregar cupons!");
    yield put(loadCuponsError());
  }
}

export function* deleteCupons({ payload: { id } }) {
  try {
    yield call(api.delete, `/coupons/${id}`);
    NotificationManager.success("", "Cupom deletado com sucesso");
  } catch (err) {
    NotificationManager.error("", "Erro ao tentar deletar o cupom!");
    yield put(loadCuponsError());
  }
}

export function* editCupons({ payload }) {
  const { id } = payload;
  try {
    yield call(api.put, `/coupons/${id}`, payload);
    history.push("/cupons");
    NotificationManager.success("", "Cupom atualizado com sucesso");
  } catch (err) {
    NotificationManager.error("", "Erro ao tentar atualizar o cupom!");
    yield put(loadCuponsError());
  }
}

export default all([
  takeLatest("@cupons/NEW_CUPOM_REQUEST", newCupom),
  takeLatest("@cupons/LOAD_CUPONS_REQUEST", loadCupons),
  takeLatest("@cupons/DELETE_CUPONS_REQUEST", deleteCupons),
  takeLatest("@cupons/EDIT_CUPONS_REQUEST", editCupons),
]);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { NotificationManager } from 'react-notifications';

import history from '~/services/history';

import {
  signInSuccess,
  signInFail,
  forgotPasswordSuccess,
  forgotPasswordError,
  signUpFail,
} from './actions';

import { updateProfileSuccess } from '../users/actions';

export function* signIn({ payload }) {
  try {
    const { username, password } = payload;

    const response = yield call(api.post, '/login', {
      username,
      password,
    });

    const { token, user } = response.data;
    yield put(signInSuccess(token, user));
    yield put(updateProfileSuccess(user));
    localStorage.setItem('token', token);
    if (JSON.parse(localStorage.getItem("papo@is_nuvem"))) window.location = `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
    history.push('/papos');
    toast.success('Olá, seja bem-vindo ao papo.vip', 'Bem-vindo');
  } catch (err) {
    yield put(signInFail());
    if (err.response) {
      NotificationManager.error(err.response.data.description);
    } else {
      NotificationManager.error('Falha inesperada');
    }
  }
}

export function* signInWithPlus({ payload }) {
  try {
    const { username, password } = payload;

    const response = yield call(api.post, '/eplus/auth/logar', {
      email: username,
      password: password,
    });

    if (response.data?.redirect?.to) { // CASO DO CADASTRO COM EMPREENDER PLUS
      window.location.href = response.data['redirect']['to'];
      return;
    } else {
      yield put(signInFail());
    }
  } catch (err) {
    yield put(signInFail());
    if (err.response) {
      NotificationManager.error(err.response.data.description);
    } else {
      NotificationManager.error('Falha inesperada');
    }
  }
}

export function* concluirLogarPlus({ payload }) {
  try {
    const response = yield call(api.get, '/eplus/auth/link-magico', {
      params: payload
    });

    const { token, user } = response.data;
    yield put(signInSuccess(token, user));
    yield put(updateProfileSuccess(user));
    localStorage.setItem('token', token);
    if (JSON.parse(localStorage.getItem("papo@is_nuvem"))) window.location = `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
    history.push('/papos');
    toast.success('Olá, seja bem-vindo ao papo.vip', 'Bem-vindo');
  } catch (err) {
    yield put(signInFail());
    if (err.response) {
      NotificationManager.error(err.response.data.description);
    } else {
      NotificationManager.error('Falha inesperada');
    }
    history.push('/');
  }
}

export function* signUp({ payload }) {
  try {
    const formData = payload;

    const response = yield call(api.post, '/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    if (response?.data?.token) {
      localStorage.setItem('token', response.data.token);
      console.log('response', response.data);
      const {
        token,
        custom_slug,
        name,
        photo_url,
        username,
      } = response.data;

      yield put(signInSuccess(token, { custom_slug, name, photo_url, username }));

      yield put(
        updateProfileSuccess({ custom_slug, name, photo_url, username })
      );
      history.push('/papos');
      toast.success('Olá, seja bem-vindo ao papo.vip', 'Bem-vindo');
    } else {
      yield put(signUpFail());
      NotificationManager.error('', 'Erro inesperado, tente novamente!');
    }
  } catch (err) {
    yield put(signUpFail());
    if (err.response) {
      NotificationManager.error(err.response.data.description);
    } else {
      NotificationManager.error('Falha inesperada');
    }
  }
}

export function* signUpWithPlus({ payload }) {
  try {
    const { name, username, password } = payload;

    const response = yield call(api.post, '/eplus/auth/cadastrar', {
      name: name,
      email: username,
      password: password,
    });

    if (response.data?.redirect?.to) { // CASO DO CADASTRO COM EMPREENDER PLUS
      window.location.href = response.data['redirect']['to'];
      return;
    } else {
      yield put(signUpFail());
    }
  } catch (err) {
    yield put(signUpFail());
    if (err.response) {
      NotificationManager.error(err.response.data.description);
    } else {
      NotificationManager.error('Falha inesperada');
    }
  }
}

export function signOut() {
  delete api.defaults.headers.Authorization;
  history.push('/login');
}

export function* forgotPassword({ payload }) {
  try {
    const response = yield call(api.post, '/forgot', {
      email: payload,
    });
    yield put(forgotPasswordSuccess());
    NotificationManager.success('', `${response.data.description}`);
  } catch (err) {
    yield put(forgotPasswordError());
    NotificationManager.error('', `${err.response.data.description}`);
  }
}

export function* forgotPasswordWithPlus({ payload }) {
  try {
    const response = yield call(api.post, '/eplus/auth/recupera-senha', {
      email: payload,
    });
    yield put(forgotPasswordSuccess());
    NotificationManager.success('', `${response.data.description}`);
  } catch (err) {
    yield put(forgotPasswordError());
    NotificationManager.error('', `${err.response.data.description}`);
  }
}

export function setTokenSignIn({ payload }) {
  if (!payload) return;
  const { token } = payload;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.Accept = 'application/json';
    api.defaults.headers['Content-Type'] = 'application/json';
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.Accept = 'application/json';
    api.defaults.headers['Content-Type'] = 'application/json';
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_PLUS_REQUEST', signInWithPlus),
  takeLatest('@auth/COMPLETE_LOGIN_WITH_PLUS', concluirLogarPlus),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_UP_PLUS_REQUEST', signUpWithPlus),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenSignIn),
  takeLatest('@auth/UPDATE_PROFILE_TOKEN', setTokenSignIn),
  takeLatest('@auth/FORGOT_PASSWORD_REQUEST', forgotPassword),
  takeLatest('@auth/FORGOT_PASSWORD_PLUS_REQUEST', forgotPasswordWithPlus),
  takeLatest('@auth/SIGN_OUT', signOut),
]);

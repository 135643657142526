import { all, call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { sendPaymentSuccess, sendPaymentError } from './actions';

export function* getDateTime({ payload }) {
  try {
  } catch (err) {}
}

export function* sendPayment({ payload }) {
  const { nome, email, user_id, papo_id, date_time } = payload;
  console.log(
    'nome:',
    nome,
    'email:',
    email,
    'id_user:',
    user_id,
    'papoid:',
    papo_id,
    'data:',
    date_time
  );
  try {
    const context = {
      start: {
        dateTime: date_time,
        timeZone: 'America/Bahia',
      },
      attendees: [
        {
          email,
        },
      ],
      description: 'Teste',
      summary: 'papo vip com: ' + email,
      conferenceData: {
        createRequest: {
          conferenceSolutionkey: {
            type: 'eventHangout',
          },
          requestId: '123abc',
        },
      },
      reminders: {
        useDefault: false,
        overrides: [
          {
            method: 'email',
            minutes: 60,
          },
          {
            method: 'popup',
            minutes: 15,
          },
        ],
      },
    };
    const response = yield call(
      api.post,
      `/users/${user_id}/${papo_id}`,
      context
    );
    const { id, owner_id } = response.data;
    if (id) {
      console.log('entrou aq no id');
      const response_payment = yield call(
        api.get,
        `/payments/${owner_id}/${email}/${id}/${papo_id}`
      );

      console.log('no pagamento', response_payment);
      const { redirect_url } = response_payment.data;
      console.log('aq no redirect', redirect_url);
      toast.success('Horário agendado com sucesso');
      yield put(sendPaymentSuccess());
      window.location.href = redirect_url;
    }
  } catch (err) {
    toast.error('Erro ao agendar horário');
    yield put(sendPaymentError());
  }
}

export default all([
  takeLatest('@users/GET_DATETIME_REQUEST', getDateTime),
  takeLatest('@users/SEND_PAYMENT_REQUEST', sendPayment),
]);

import { put, call, delay, all, takeLatest } from "redux-saga/effects";
import { DELETE, POST } from "~/store/api";


export function* integrate(action) {

    console.log("TESTE SAGA INTEGRATE ACTION", action)

    yield put({ type: "INTEGRATION_REQUEST", })

    yield delay(1000)

    const result = yield call(POST, { endpoint: "/integrations", data: action.payload })
    if (result == false) {
        yield put({ type: "INTEGRATION_ERROR", payload: result ? result.data : [] })
        yield put({ type: "INTEGRATION_RESET" })
    } else if (result.data.error) {
        yield put({ type: "INTEGRATION_ERROR", payload: result.data.error ? result.data : [] })
        yield put({ type: "INTEGRATION_RESET" })
    } else {
        yield put({ type: "INTEGRATION_SUCCESS", payload: result.data })

        localStorage.setItem('papovip@nuvemshop', JSON.stringify(result.data.data))

        yield put({ type: "INTEGRATION_RESET" })

    }

    localStorage.removeItem('papo@is_nuvem');
}

export function* deleteIntegration(action) {
    yield put({ type: "INTEGRATION_DELETE_REQUEST", })
    const result = yield call(DELETE, { endpoint: `/integrations/nuvemshop` })
    if (result === false) {
        yield put({ type: "INTEGRATION_DELETE_ERROR" })
    } else {
        yield put({ type: "INTEGRATION_DELETE_SUCCESS", payload: result.data })

        localStorage.removeItem('papovip@nuvemshop')

    }
    yield put({ type: "INTEGRATION_DELETE_RESET" })
}


export default all([
    takeLatest("SAGA_DELETE_INTEGRATION", deleteIntegration),
    takeLatest("SAGA_ADD_INTEGRATION", integrate),
]);
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '~/services/api';
import { useDispatch } from 'react-redux';

import { requestAuthMP } from '~/store/modules/users/actions';

function Authmp() {
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    async function fetchAPI() {
      try {
        const code = (location.search.match(/code=([^&]+)/) || [])[1];
        const token = localStorage.getItem('token');

        await api.get(`/authmp?code=${code}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(requestAuthMP());
        window.location.assign(`${process.env.REACT_APP_BASE_URL}/minhaconta`);
      } catch (err) {
        console.log(err);
      }
    }
    fetchAPI();
  }, [location]);

  return <p>Redirecionando...</p>;
}

export default Authmp;

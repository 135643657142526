import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .bg {
    margin-top: 20px;
    /* background: #5a00c9 !important; */
    margin-right: 0;
    height: 47px;
    font-weight: 700;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    &:hover {
      background: #6a19ce !important;
      cursor: pointer;
    }
    &:focus {
      background: #6a19ce !important;
    }
  }

  .google {
    font-size: 18px;
    background: #ff108c !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 47px;
    padding: 0;
    border-radius: 8px;
    border: 0;
    &:hover {
      background: #fe2897 !important;
    }
    &:focus {
      background: #fe2897 !important;
    }
  }
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  button {
    background: none;
    border: 0;
    position: absolute;
    top: 8px;
    right: 5px;
    color: #6a19ce;
    font-size: 16px;
    &:focus {
      border: 0;
      outline: none;
    }
  }
`;

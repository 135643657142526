import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import '~/styles/app/app.scss';
import { NotificationContainer } from 'react-notifications';
import Routes from './routes';
import HttpsRedirect from 'react-https-redirect';

import { PersistGate } from 'redux-persist/integration/react';

import './config/ReactotronConfig';

import history from './services/history';
import { store, persistor } from '~/store';

//teste

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HttpsRedirect>
          <Router history={history}>
            <Routes />
            <NotificationContainer />
          </Router>
        </HttpsRedirect>
      </PersistGate>
    </Provider>
  );
}

import React, { useState } from 'react';
import { Breadcrumb } from '@gull';

import { NotificationManager as toast } from 'react-notifications';
import { BoxForm, BtnCancelar } from './styles';
import moment from 'moment';
import api from '~/services/api';
import history from '~/services/history';

export default function Create() {
  const empty_papo = {
    value: 0,
    time: 0,
    custom_field_value: '',
    availability_start: '00:00',
    availability_end: '00:00',
    available_days: {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
    active: true,
    show_custom_field: false,
  };

  const [edit_papo, setEditPapo] = useState(empty_papo);
  const token = localStorage.getItem('token');

  function handleEditPapo(e) {
    let copy = Object.assign({}, edit_papo);
    copy[e.target.name] = e.target.value;
    setEditPapo(copy);
  }

  function handleCheckboxDays(e) {
    let copy = Object.assign({}, edit_papo);
    copy.available_days[e.target.name] = e.target.checked;
    setEditPapo(copy);
  }

  function handleAvailabilityStart(time) {
    if (time) {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = moment(time._d).format('HH:mm');
      setEditPapo(copy);
    } else {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = '00:00';
      setEditPapo(copy);
    }
  }

  function handleAvailabilityEnd(time) {
    if (time) {
      let copy = Object.assign({}, edit_papo);
      copy.availability_end = moment(time._d).format('HH:mm');
      setEditPapo(copy);
    } else {
      let copy = Object.assign({}, edit_papo);
      copy.availability_start = '00:00';
      setEditPapo(copy);
    }
  }

  function handleCheckBoxShowCustomField(e) {
    e.persist();
    setEditPapo((prevState) => ({
      ...prevState,
      ...edit_papo,
      show_custom_field: e.target.checked,
    }));
  }

  async function handleSavePapo(e) {
    e.preventDefault();

    if (edit_papo.time <= 0) {
      toast.error('', 'A duração deve ser maior que zero');
      return;
    }

    if (
      edit_papo.availability_start === '00:00' &&
      edit_papo.availability_end === '00:00'
    ) {
      toast.error('', 'Horário disponível não preenchido');
      return;
    }

    if (edit_papo.availability_start > edit_papo.availability_end) {
      toast.error('', 'O horário inicial não pode ser maior que o final');
      return;
    }

    try {
      toast.success('', 'Aguarde, seu papo está sendo criado!');
      await api.post(`papos`, edit_papo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('', 'Papo criado com sucesso!!');
      history.push('/papos');
    } catch (err) {
      console.log(err);
      toast.error('', 'Erro ao criar papo!');
    }
  }

  return (
    <>
      <div>
        <div className="card">
          <div className="card-header">Cadastrar Novo Papo</div>
          <div className="card-body">
            <BoxForm>
              <form className="form" onSubmit={handleSavePapo}>
                <div className="div-input">
                  <label htmlFor="time-duration">Duração do papo</label>
                  <div>
                    <input
                      type="number"
                      required
                      onChange={handleEditPapo}
                      className="form-control"
                      name="time"
                      id="time-duration"
                      placeholder="Ex: 30"
                    />
                    <span>Minutos</span>
                  </div>
                </div>

                {/* <div className="div-input">
                  <label htmlFor="papo-value">Valor do papo</label>
                  <div>
                    <input
                      type="number"
                      required
                      onChange={handleEditPapo}
                      className="form-control"
                      name="value"
                      id="papo-value"
                      placeholder="Ex: 89.99"
                    />
                    <span>Reais</span>
                  </div>
                </div> */}

                <div className="div-input">
                  <label htmlFor="interval_between_papos">
                    Intervalo antes do papo
                  </label>
                  <div>
                    <input
                      type="number"
                      required
                      min="0"
                      onChange={handleEditPapo}
                      className="form-control"
                      name="interval_between_papos"
                      id="interval_between_papos"
                      placeholder="Ex: 30"
                    />
                    <span>Minutos</span>
                  </div>
                </div>
                <div className="div-input">
                  <label htmlFor="minimum_time_start_papos">
                    Tempo Mínimo Para Agendar o Papo
                  </label>
                  <div>
                    <input
                      type="number"
                      required
                      min="15"
                      onChange={handleEditPapo}
                      className="form-control"
                      name="minimum_time_start_papos"
                      id="minimum_time_start_papos"
                      placeholder="Ex: 30"
                    />
                    <span>Minutos</span>
                  </div>
                </div>

                <h2>Disponibilidade</h2>

                <div className="div-days">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="monday"
                      id="monday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="monday" className="form-check-label">
                      Segunda
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="tuesday"
                      id="tuesday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="tuesday" className="form-check-label">
                      Terça
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="wednesday"
                      id="wednesday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="wednesday" className="form-check-label">
                      Quarta
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="thursday"
                      id="thursday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="thursday" className="form-check-label">
                      Quinta
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="friday"
                      id="friday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="friday" className="form-check-label">
                      Sexta
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="saturday"
                      id="saturday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="saturday" className="form-check-label">
                      Sábado
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="sunday"
                      id="sunday"
                      onChange={handleCheckboxDays}
                      className="form-check-input"
                    />
                    <label htmlFor="sunday" className="form-check-label">
                      Domingo
                    </label>
                  </div>
                </div>

                <div className="div-time-pickers">
                  <h2>Horário disponível</h2>
                  <div className="form-group">
                    <input
                      type="time"
                      min="00:00"
                      max="23:59"
                      name="availability_start"
                      required
                      onChange={handleEditPapo}
                      //   value={moment(edit_papo.availability_start, "HH:mm")}
                      format={'HH:mm'}
                    />
                    <span> Até </span>
                    <input
                      type="time"
                      min="00:00"
                      max="23:59"
                      required
                      name="availability_end"
                      onChange={handleEditPapo}
                      //   value={moment(edit_papo.availability_end, "HH:mm")}
                      format={'HH:mm'}
                      className="timePicker"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label
                      data-toggle="tooltip"
                      title="A resposta desse campo será anexada ao evento criado no calendário"
                      htmlFor="custom_field_value"
                    >
                      Ex: Sobre o que deseja falar?
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        onChange={handleEditPapo}
                        className="form-control mr-2"
                        name="custom_field_value"
                        id="custom_field_value"
                      />

                      <input
                        type="checkbox"
                        name="show_custom_value"
                        onChange={handleCheckBoxShowCustomField}
                        id="show_custom_value"
                        className="mr-1"
                      />

                      <label className="m-0" htmlFor="show_custom_value">
                        Exibir
                      </label>
                    </div>
                    <span>
                      A resposta desse campo será anexada ao evento criado no
                      calendário
                    </span>
                  </div>

                  {/* <div className="pretty p-default p-smooth p-fill">
                    </div> */}
                </div>

                <div className="d-flex align-items-center mt-4">
                  <BtnCancelar
                    to="/papos"
                    className="btn-raised btn-raised-light mr-4 text-capitalize btn btn-light"
                  >
                    Cancelar
                  </BtnCancelar>
                  <button type="submit" className="btn btn-primary btn-save">
                    Salvar
                  </button>
                </div>
              </form>
            </BoxForm>
          </div>
        </div>
      </div>
    </>
  );
}

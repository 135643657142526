import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthLayout from '../pages/_layouts/Auth';
import DefaultLayout from '../pages/_layouts/Default';
import GlobalStyle from '~/styles/globals';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  template,
  location,
  ...rest
}) {
  const signed = localStorage.getItem('token') || false;

  useEffect(function () {
    try {
      if (isPrivate) {
        let toolbarTimeout = setInterval(() => {
          if (signed) {
            window.empreenderPlusApiInstancia({
              caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_BASE_URL + "/eplus/hub", //OBRIGATORIO
              afiliado: "", //OPCIONAL
              insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
              app: process.env.REACT_APP_PLUS_APP_NAME, //code do app - OBRIGATORIO
              // callbackLogout: () => console.log("logout"),
              // headers - OPCIONAL
              headers: {
                "Authorization": "Bearer " + signed,
                "Content-Type": "application/json",
              },
            });

            clearInterval(toolbarTimeout);
          }
        }, 250);
      }
    } catch (e) {
      console.error("empreenderPlusApiInstancia - error", e);
    }

  }, [signed, isPrivate, window?.empreenderPlusApiInstancia]);

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/papos" />;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;
  const { path, isExact } = rest.computedMatch;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <>
            {isPrivate && <div className="insert-toolbar-plus" style={{ position: "relative", minHeight: "75px" }}></div>}
            <GlobalStyle color={path === '/' && !isExact ? false : true} />
            <Component {...props} />
          </>
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

RouteWrapper.defaultProps = {
  location: {
    pathname: '',
  },
};

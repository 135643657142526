export function signInRequest(data) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: data,
  };
}

export function signInWithPlusRequest(data) {
  return {
    type: '@auth/SIGN_IN_PLUS_REQUEST',
    payload: data,
  };
}

export function completeLoginWithPlus(data) {
  return {
    type: '@auth/COMPLETE_LOGIN_WITH_PLUS',
    payload: data,
  };
}

export function signUpRequest(data) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: data,
  };
}

export function signUpWithPlusRequest(data) {
  return {
    type: '@auth/SIGN_UP_PLUS_REQUEST',
    payload: data,
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpSuccess(data) {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: { data },
  };
}

export function signInFail() {
  return {
    type: '@auth/SIGN_IN_FAIL',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signUpFail() {
  return {
    type: '@auth/SIGN_UP_FAIL',
  };
}

export function forgotPasswordRequest(data) {
  return {
    type: '@auth/FORGOT_PASSWORD_REQUEST',
    payload: data,
  };
}

export function forgotPasswordWithPlusRequest(data) {
  return {
    type: '@auth/FORGOT_PASSWORD_PLUS_REQUEST',
    payload: data,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: '@auth/FORGOT_PASSWORD_SUCCESS',
  };
}

export function forgotPasswordError() {
  return {
    type: '@auth/FORGOT_PASSWORD_ERROR',
  };
}

export function updateProfileToken(token) {
  return {
    type: '@auth/UPDATE_PROFILE_TOKEN',
    payload: { token },
  };
}

export function videoRecordRequest(data) {
  return {
    type: '@auth/VIDEO_RECORD_REQUEST',
    payload: data,
  };
}
